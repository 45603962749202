import React, { useState, useRef, useEffect } from "react"

import { useGlobal } from "@hooks/useGlobal"

export const CheckoutContext = React.createContext({})

export const CheckoutProvider = ({ children }) => {
  const { handleGlobalGroupReady } = useGlobal()
  const [checkout, setCheckout] = useState(false)

  const prevCheckout = useRef(checkout)

  // save previous checkout in the prevCheckout ref
  useEffect(() => {
    if (!prevCheckout.current) {
      prevCheckout.current = checkout
    }
  }, [checkout])

  // compare previous checkout and current checkout, if previous is false and current is not false, then set the checkout ready to true
  // if (prevCheckout.current === false && checkout) {
  //   handleGlobalGroupReady("checkout")
  // }

  useEffect(() => {
    if (prevCheckout.current === false && checkout) {
      handleGlobalGroupReady("checkout")
    }
  }, [prevCheckout, checkout, handleGlobalGroupReady])

  const values = {
    checkout,
    setCheckout,
  }

  return <CheckoutContext.Provider value={{ ...values }}>{children}</CheckoutContext.Provider>
}
