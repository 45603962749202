import React from "react"

import { withBooking } from "./withBooking"
import { Popup } from "../Modals/Popup/Popup"
import { BookingContent, BookingContainer, IconPrev, IconWrapper } from "./BookingStyles"

interface Props {
  active: boolean
  id: string
  setActive: Function
}

export const Booking = withBooking(({ active, id, setActive }: Props) => (
  <Popup active={active} noClose setActive={setActive} transparent width={`lg`}>
    <BookingContainer>
      <IconWrapper>
        <IconPrev onClick={() => setActive(false)}></IconPrev>
      </IconWrapper>
      <BookingContent id={id} />
    </BookingContainer>
  </Popup>
))
