import { useCallback, useContext } from "react"

import { useApp } from "./useApp"
import { useCart } from "./useCart"
import { useCore } from "./useCore"
import { useShopify } from "./useShopify"
import { useQueries } from "@hooks/useQueries"
import { useCustomerLogout } from "./useCustomer"
import { useShop } from "./useShop"
import { CheckoutContext } from "@providers/checkout"
import { useLocation } from "./useLocation"

export const useCheckoutContext = () => {
  const checkoutData = useContext(CheckoutContext)
  return { ...checkoutData }
}

export const useCheckout = () => {
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()
  const {
    mutations: {
      CHECKOUT_CREATE,
      CHECKOUT_ATTRIBUTES_UPDATE,
      CHECKOUT_SHIPPING_ADDRESS_UPDATE,
      CHECKOUT_EMAIL_UPDATE,
      CHECKOUT_DISCOUNT_APPLY,
      CHECKOUT_GIFTCARDS_APPEND,
      CHECKOUT_CUSTOMER_ASSOCIATE,
    },
    queries: { GET_CHECKOUT, GET_SHIPPING_RATES, GET_PRODUCTS_BY_HANDLE },
  } = useQueries()
  const { excludeGroupedAndWrappingItems } = useCart()
  const { shop } = useShop()
  const { checkout, setCheckout: saveCheckout } = useCheckoutContext()

  const { cartNormaliser, useMutation, useQuery, client } = useShopify()
  const { logoutCustomer } = useCustomerLogout()
  const { getShop } = useShop()
  const { shopifyStore, shopifyStoreDomain } = useLocation()
  const checkoutId = storage.get(keys?.checkout) // actually the cart id - blake

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE)
  const [checkoutAttributeUpdate] = useMutation(CHECKOUT_ATTRIBUTES_UPDATE)
  const [checkoutCustomerAssociate] = useMutation(CHECKOUT_CUSTOMER_ASSOCIATE)
  const [checkoutShippingAddressUpdate] = useMutation(CHECKOUT_SHIPPING_ADDRESS_UPDATE)
  const [checkoutEmailUpdate] = useMutation(CHECKOUT_EMAIL_UPDATE)
  const [checkoutDiscountApply] = useMutation(CHECKOUT_DISCOUNT_APPLY)
  const [checkoutGiftcardAppend] = useMutation(CHECKOUT_GIFTCARDS_APPEND)

  const { refetch: getCheckoutQuery } = useQuery(GET_CHECKOUT, { fetchPolicy: "no-cache", skip: true })
  const { refetch: getShippingRatesQuery } = useQuery(GET_SHIPPING_RATES, { fetchPolicy: "no-cache", skip: true })

  const getCheckout = useCallback(async () => {
    try {
      if (checkoutId) {
        const {
          data: { node: checkout },
        } = await getCheckoutQuery({ id: checkoutId }) // actually the cart ID - blake
        return checkout
      }
      return false
    } catch (error) {
      console.error(error)
    }
  }, [checkoutId, getCheckoutQuery])

  const setCheckout = useCallback(
    async checkout => {
      // Make the function asynchronous
      try {
        const normalizedCheckout = await cartNormaliser(checkout)
        saveCheckout(normalizedCheckout)
        storage.set(keys?.checkout, checkout?.id)
      } catch (error) {
        console.error(error)
      }
    },
    [saveCheckout]
  )

  const createCheckout = useCallback(
    async (presentmentCurrencyCode = "AU", forceNew = false) => {
      try {
        const existingCheckout = !forceNew && (await getCheckout())
        const conditions = forceNew || !existingCheckout?.id || Object.keys(existingCheckout).length < 1
        //  Blake: Carts may remain after a checkout has been completed that relates to the cart. Test this.
        if (conditions) {
          const {
            data: {
              cartCreate: { cart },
            },
          } = await checkoutCreate({
            variables: {
              input: {
                buyerIdentity: {
                  countryCode: "AU", // using variable, but its actually the currency code - useShop is providing us with the currency of the shop not the buyer. I think we should use an ENV variable to define this instead. - Blake
                },
              },
            },
          })

          if (cart) {
            setCheckout(cart)
          }
        } else {
          setCheckout(existingCheckout)
        }
        getShop()
      } catch (error) {
        storage.remove(keys?.checkout)
      }
    },
    [getCheckout, setCheckout, checkoutCreate, getShop]
  )

  const updateAttributes = useCallback(
    async input => {
      const {
        data: { checkoutAttributesUpdateV2: data },
      } = await checkoutAttributeUpdate({
        variables: { checkoutId, input },
      })
      setCheckout(data?.checkout)
    },
    [checkoutAttributeUpdate, setCheckout]
  )

  const updateCustomer = useCallback(
    async customerAccessToken => {
      const {
        data: { checkoutCustomerAssociateV2: data },
      } = await checkoutCustomerAssociate({
        variables: { cartId: checkoutId, customerAccessToken },
      })
      setCheckout(data?.checkout)
    },
    [checkoutCustomerAssociate, setCheckout]
  )

  const updateShippingAddress = useCallback(
    async input => {
      const {
        data: { checkoutShippingAddressUpdateV2: data },
      } = await checkoutShippingAddressUpdate({
        variables: {
          checkoutId,
          shippingAddress: {
            firstName: input?.firstName,
            lastName: input?.lastName,
            address1: input?.address1,
            address2: input?.address2,
            city: input?.city,
            country: input?.country,
            phone: input?.phone,
            province: input?.province,
            zip: input?.zip,
          },
        },
      })
      setCheckout(data?.checkout)
    },
    [checkoutShippingAddressUpdate, setCheckout]
  )

  const updateEmail = useCallback(
    async email => {
      const {
        data: { cartBuyerIdentityUpdate: data },
      } = await checkoutEmailUpdate({
        variables: {
          cartId: checkoutId,
          buyerIdentity: {
            email,
          },
        },
      })
      setCheckout(data?.checkout)
    },
    [checkoutEmailUpdate, setCheckout]
  )

  const applyDiscountCode = useCallback(
    async discountCode => {
      const {
        data: { checkoutDiscountCodeApplyV2: data },
      } = await checkoutDiscountApply({
        variables: { checkoutId, discountCode },
      })

      if (!data.checkoutUserErrors.length) {
        storage.set(
          keys?.discounts,
          [...(storage.get(keys?.discounts) || []), discountCode].filter((value, index, self) => self.indexOf(value) === index)
        )
        setCheckout(data?.checkout)
      } else {
        return data
      }
    },
    [checkoutDiscountApply, setCheckout]
  )

  const applyGiftCardCode = useCallback(
    async giftCardCode => {
      const {
        data: { checkoutGiftCardsAppend: data },
      } = await checkoutGiftcardAppend({
        variables: { checkoutId, giftCardCodes: [giftCardCode] },
      })

      if (!data.checkoutUserErrors.length) {
        setCheckout(data.checkout)
        return this
      } else {
        return data
      }
    },
    [checkoutGiftcardAppend, setCheckout]
  )

  const getShippingRates = useCallback(async () => {
    const {
      data: { node: checkout },
    } = await getShippingRatesQuery({ id: checkoutId })

    setCheckout(checkout)
    return checkout
  }, [getShippingRatesQuery, setCheckout])

  const applyDiscounts = useCallback(async () => {
    const discountCodes = storage.get(keys?.discounts) || []
    for (const discountCode of discountCodes) {
      await applyDiscountCode(discountCode)
    }
  }, [applyDiscountCode])

  const duplicateCheckout = useCallback(
    async (changes = {}) => {
      const { currencyCode, customAttributes, lineItems, note, email, shippingAddress } = checkout
      const discounts = storage.get(keys?.discounts) || []

      const {
        data: { checkoutCreate: data },
      } = await checkoutCreate({
        variables: {
          input: {
            ...(email && { email }),
            ...(note && { note }),
            ...(customAttributes && {
              customAttributes:
                customAttributes?.map(({ key, value }) => ({
                  key,
                  value,
                })) || [],
            }),
            presentmentCurrencyCode: currencyCode,
            lineItems:
              lineItems?.map(item => ({
                variantId: item?.variant.id,
                quantity: item?.quantity || 1,
                customAttributes:
                  item?.customAttributes?.map(({ key, value }) => ({
                    key,
                    value,
                  })) || [],
              })) || [],
            shippingAddress: shippingAddress
              ? {
                  address1: shippingAddress.address1,
                  address2: shippingAddress.address2,
                  city: shippingAddress.city,
                  company: shippingAddress.company,
                  country: shippingAddress.country,
                  firstName: shippingAddress.firstName,
                  lastName: shippingAddress.lastName,
                  phone: shippingAddress.phone,
                  province: shippingAddress.province,
                  zip: shippingAddress.zip,
                }
              : undefined,
            ...changes,
          },
        },
      })
      if (!discounts.length) setCheckout(data?.checkout)
      if (discounts.length) applyDiscounts()
    },
    [checkout, checkoutCreate, setCheckout, applyDiscounts]
  )

  const updateCurrency = useCallback(
    async presentmentCurrencyCode => {
      await duplicateCheckout({
        presentmentCurrencyCode: presentmentCurrencyCode,
      })
      getShop()
    },
    [duplicateCheckout, getShop]
  )

  const count =
    excludeGroupedAndWrappingItems(checkout?.lines || [])?.reduce(
      (count, lineItem, i) => (i ? count + parseInt(lineItem.quantity) : parseInt(lineItem.quantity)),
      0
    ) || 0

  const checkoutUrl = checkout.webUrl ? checkout.webUrl.replace(`${shopifyStore}.myshopify.com`, shopifyStoreDomain) : ""

  return {
    checkout,
    checkoutUrl,
    createCheckout,
    getCheckout,
    setCheckout,
    updateCurrency,
    updateAttributes,
    updateShippingAddress,
    updateEmail,
    updateCustomer,
    applyDiscountCode,
    applyGiftCardCode,
    getShippingRates,
    count,
  }
}
