import React, { useCallback, useState } from "react"
import { navigate } from "gatsby"

import { useLanguage } from "@hooks/useLanguage"
import { useApp } from "@hooks/useApp"
import { useCheckout, useCheckoutContext } from "@hooks/useCheckout"
import { useCustomerDetails } from "@hooks/useCustomer"
import { useFunctions } from "@hooks/useFunctions"

export const withCartFormCheckout = Component => ({ name = `CartFormCheckout`, setActiveCart, ...props }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const locales = useLanguage(`cart`)
  const { updateCustomer } = useCheckout()
  const { checkout } = useCheckoutContext()
  const { customer, customerAccessToken } = useCustomerDetails()
  const { checkoutMultipass } = useFunctions()
  const [loading, setLoading] = useState(false)

  const handleCheckout = useCallback(async () => {
    window.location = checkout.checkoutUrl

    if (customerAccessToken && customer?.email) {
      // if (checkout?.email !== customer?.email) {
      //   setLoading(true)
      //   await updateCustomer(customerAccessToken)
      //   setLoading(false)
      // }
      // Blake: No longer using multipass as it requires a BE upgrade
      // await checkoutMultipass(customer?.email, checkout.id, checkoutUrl)
    } else {
      // navigate(routes.CART_LOGIN) Blake: Removes navigate to login

      if (setActiveCart) {
        setActiveCart(false)
      }
    }
  })

  Component.displayName = name
  return <Component {...props} handleCheckout={handleCheckout} loading={loading} locales={locales} />
}
