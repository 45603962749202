import { useRoutes } from "./useRoutes"
import { useSettings } from "./useSettings"

export const useNavigation = () => {
  const { linkResolver } = useRoutes()
  const { menus } = useSettings()

  const navigationNormaliser = ({ items, slug, title }) => {
    if (!(items && items.length)) return {}

    const submenuNormaliser = item => {
      if (!item) return {}

      const content = item[item?._type?.toLowerCase()?.replace(`navigation`, ``)?.replace(`page`, ``)] || null
      const link = linkResolver(content || item)

      return {
        key: item._key,
        content,
        link,
        image: item?.image,
        items: item?.items?.length > 0 ? item?.items?.map(item => submenuNormaliser(item)) : [],
        title: item?.title?.toString() || "",
      }
    }

    return {
      handle: slug?.current,
      items: items.map(item => submenuNormaliser(item)),
      title: title || "",
    }
  }

  const navigation = {
    header: navigationNormaliser(menus?.header),
    footer: navigationNormaliser(menus?.footer),
    products: navigationNormaliser(menus?.products),
  }

  return { navigation, navigationNormaliser }
}
