import React from "react"

import { withStoresForm } from "./withStoresForm"
import { Title, Form, Button, StyledInput, IconArrowRight } from "../StoresStyles"

export const StoresForm = withStoresForm(({ invertedTheme, handleChange, handleSubmit, locales, value }) => (
  <>
    <Title>{locales.locator}</Title>

    <Form onSubmit={handleSubmit}>
      <StyledInput
        inverted={invertedTheme}
        placeholder={locales.locatorPlaceholder}
        onChange={handleChange}
        required
        type={`text`}
        name={`location`}
        value={value}
      />

      <Button type={`submit`}>
        <IconArrowRight />
      </Button>
    </Form>
  </>
))
