import React, { useState } from "react"

import { useNavigation } from "@hooks/useNavigation"
import { useSettings } from "@hooks/useSettings"

export const withNavigationMain = Component => ({ name = `NavigationMain`, activeMenu, setActiveMenu }) => {
  const [activeImage, setActiveImage] = useState(null)
  const {
    navigation: { header },
  } = useNavigation()
  const { appearance } = useSettings()

  const images = []
  const getNavImages = item => {
    item?.items?.map(subItem => {
      if (subItem.image) {
        images.push({
          ...subItem.image,
          key: subItem.key,
        })
      }

      if (subItem?.items?.length > 0) {
        getNavImages(subItem)
      }
    })
  }

  getNavImages(header)

  Component.displayName = name
  return (
    <Component
      activeImage={activeImage}
      activeMenu={activeMenu}
      images={images}
      navigation={header}
      setActiveMenu={setActiveMenu}
      setActiveImage={setActiveImage}
      appearance={appearance}
    />
  )
}
