import React from "react"

import { withCartDrawerCount } from "./withCartDrawerCount"
import { HiddenSpan } from "../../Styled/Accessibility"
import { CartCounter, IconCart, CartItems } from "../CartStyles"

export const CartDrawerCount = withCartDrawerCount(({ count, locales }) => (
  <CartCounter>
    <IconCart />
    <HiddenSpan>{locales.title}</HiddenSpan> {count ? <CartItems>{count}</CartItems> : null}
  </CartCounter>
))
