import { memo } from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { Label, H5, H6 } from "../Styled/Text"
import { Input } from "../Styled/Input"

import ArrowRight from "static/icons/theme/arrow-right.svg"

export const Title = tw(Label)`mb-1-6`
export const Form = tw.form`relative flex items-center mt-1-6 mb-2-4`
export const Button = styled.button`
  ${tw`absolute right-0 px-1 top-0 bottom-0 focus:outline-none`}
  ${({ disabled }) => disabled && `pointer-events-none opacity-75`}
`
export const StyledInput = tw(Input)`pr-4 mt-0-6 border-grey-DEFAULT`
export const IconArrowRight = tw(ArrowRight)`block w-1 h-1`
export const StyledH5 = memo(tw(H5)`mt-5-8`)
export const StyledH6 = memo(tw(H6)`mt-1-2 mb-5-8 max-w-39-2`)
export const PopupContent = memo(tw.div`flex flex-col  w-full items-center justify-center text-center px-3-2 md:px-0`)
