import { useCallback } from "react"
import { useImage } from "./useImage"
import { useRoutes } from "./useRoutes"

export const useSanity = () => {
  const { getFluidImage } = useImage()
  const { linkResolver, routeResolver } = useRoutes()

  const textNormaliser = (text, locale = `enAu`) => (text && text[locale] ? text[locale] : null)

  const richTextNormaliser = (content, locale = `enAu`) =>
    textNormaliser(content, locale)
      ? textNormaliser(content, locale)
          .map(block => block.children.map(child => child.text).join(``))
          .join(" ")
          .trim()
      : null

  const keyNormaliser = key => key.replace(`_raw`, ``).charAt(0).toLowerCase() + key.replace(`_raw`, ``).slice(1)

  const summaryNormaliser = content => content?.[0]?.children?.[0]?.text

  const contentLinkResolver = link => {
    const validLink = link => fn => {
      if (!link || (!link?.link && !link?.link?._type) || (Array.isArray(link?.link) && !link?.link[0])) return null

      return fn(link)
    }

    const url = link?.link?.[0]?.link || link?.link?.link

    const item = typeof url === `string` ? { type: `customLink`, url } : url

    return validLink(link)(() => linkResolver(item)?.url)
  }

  const linkNormaliser = item =>
    !(item?.link?.length > 0)
      ? null
      : item.link[0]._type.includes(`external`)
      ? { title: textNormaliser(item?.title), type: "external", url: item.link[0].link }
      : { title: textNormaliser(item?.title), type: "internal", url: linkResolver(item.link[0].link)?.url }

  const rawContentNormaliser = ({ _rawContent, ...data }) =>
    _rawContent && _rawContent.length
      ? _rawContent.map(item => {
          const { content = [] } = data
          return item._type === "textGrid"
            ? {
                ...item,
                content: [item.content.map(block => ({ ...textNormaliser(block), key: block._key }))],
              }
            : item._type === "textBlock"
            ? [...textNormaliser(item)]
            : item._type === "imageType"
            ? {
                ...item,
                key: item._key,
                type: "image",
                ...(content.find(c => c._key === item._key) || {}).asset,
              }
            : item._type === "imageLinkType"
            ? {
                ...item.image,
                key: item._key,
                type: "image",
                link: item?.links?.length > 0 ? linkResolver(item.links[0])?.url : null,
                ...(content.find(c => c._key === item._key) || {}).image?.asset,
              }
            : { ...item, key: item._key, type: item._type }
        })
      : null

  const heroNormaliser = useCallback(
    (item = {}) => ({
      ...item,
      content: item?.content?.map(content => {
        return {
          ...content,
          heading: content?.title ? content?.title?.enAu : null,
          subheading: content?.subtitle ? content?.subtitle?.enAu : null,
          ...(content._type === `heroBannerImage`
            ? {
                image: getFluidImage(content.image, { maxWidth: 3000 }),
              }
            : content._type === `heroBannerVideo`
            ? {
                video: content?.video?.link
                  ? {
                      link: content.video.link,
                    }
                  : {},
                videoMobile: content?.videoMobile?.link
                  ? {
                      link: content.videoMobile.link,
                    }
                  : {},
              }
            : {}),
          imageMobile: getFluidImage(content.imageMobile, { maxWidth: 1000 }),
          primaryButtonLink: contentLinkResolver(content?.primaryButtonLink),
          secondaryButtonLink: contentLinkResolver(content?.secondaryButtonLink),
        }
      }),
      type: item?._type,
      key: item?._key,
    }),
    [getFluidImage, contentLinkResolver]
  )

  return {
    keyNormaliser,
    contentLinkResolver,
    heroNormaliser,
    linkNormaliser,
    rawContentNormaliser,
    richTextNormaliser,
    summaryNormaliser,
    textNormaliser,
  }
}
