import React from "react"
import { Link } from "gatsby"

import { withNavigationAccount } from "./withNavigationAccount"
import { WishlistCount } from "../../Wishlist/Count/WishlistCount"
import { CartDrawerCount } from "../../Cart/Drawer/CartDrawerCount"
import { HiddenSpan } from "../../Styled/Accessibility"
import {
  IconAccount,
  IconClose,
  IconMenu,
  IconSearch,
  Section,
  List,
  Notification,
  ListItem,
  ListItemHiddenSmall,
  ListItemHiddenLarge,
  Button,
  MobileNav,
  StyledButton,
} from "./NavigationAccountStyles"

interface Props {
  accountLink: any
  added: boolean
  activeCart: boolean
  setActiveCart: any
  activeMenu: boolean
  setActiveMenu: any
  activeSearch: boolean
  setActiveSearch: any
  location: any
  locales: any
  routes: any
  toggleCart: any
  toggleMenu: any
  toggleSearch: any
  appearance: any
}

export const NavigationAccount = withNavigationAccount(
  ({ activeMenu, accountLink, added, locales, location, routes, toggleCart, toggleMenu, toggleSearch, appearance }: Props) => (
    <Section>
      <List>
        {appearance?.url && appearance?.text ? (
          <ListItem>
            <StyledButton as={Link} to={appearance?.url} target="_blank" btnStyle={appearance?.btnStyle}>
              {appearance?.text}
            </StyledButton>
          </ListItem>
        ) : null}
        <ListItem>
          <Button as={Link} to={accountLink}>
            <IconAccount />
            <HiddenSpan>{locales.title}</HiddenSpan>
          </Button>
        </ListItem>

        <ListItem>
          <WishlistCount addedToWishlist={added} />
        </ListItem>

        <ListItem noMarginLarge noTransition>
          <List>
            <ListItem noMargin translate={added}>
              <Button onClick={() => toggleCart()}>
                <CartDrawerCount />
              </Button>
            </ListItem>
            <ListItemHiddenLarge>
              {location?.pathname !== routes.SEARCH && (
                <Button onClick={() => toggleSearch()}>
                  <IconSearch />
                  <HiddenSpan>{locales.search}</HiddenSpan>
                </Button>
              )}
            </ListItemHiddenLarge>
            <ListItemHiddenSmall translate={added}>
              {location?.pathname !== routes.SEARCH && (
                <Button onClick={() => toggleSearch()}>
                  <IconSearch />
                  <HiddenSpan>{locales.search}</HiddenSpan>
                </Button>
              )}
            </ListItemHiddenSmall>
          </List>

          <Notification translate={!added}>{locales.saved}</Notification>
        </ListItem>

        <MobileNav onClick={() => toggleMenu()}>
          <IconMenu active={!activeMenu ? `active` : undefined} />
          <IconClose active={activeMenu ? `active` : undefined} />
          <HiddenSpan>{locales.menu}</HiddenSpan>
        </MobileNav>
      </List>
    </Section>
  )
)
