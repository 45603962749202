import React from "react"

import { withSubscribe } from "./withSubscribe"
import { Popup } from "../Modals/Popup/Popup"
import { P } from "../Styled/Text"
import { Title, Form, Button, StyledInput, IconArrowRight, StyledH5, StyledH6, PopupContent } from "./SubscribeStyles"

export const Subscribe = withSubscribe(({ activePopup, email, handleChange, handleSubmit, invertedTheme, locales, loading, setActivePopup }) => (
  <>
    <Title>{locales.subscribeTitle}</Title>
    <P>{locales.subscribeDescription}</P>
    <Form onSubmit={handleSubmit}>
      <StyledInput
        disabled={loading}
        inverted={invertedTheme}
        name={`email`}
        onChange={handleChange}
        placeholder={locales.emailAddress}
        required
        type={`email`}
        value={email}
      />
      <Button disabled={loading} type={`submit`}>
        <IconArrowRight />
      </Button>
    </Form>

    <Popup active={activePopup} setActive={setActivePopup}>
      <PopupContent>
        <StyledH5>{locales.subscribeSuccessTitle}</StyledH5>
        <StyledH6 as={`p`}>{locales?.subscribeSuccessDescription?.replace(`{email}`, email)}</StyledH6>
      </PopupContent>
    </Popup>
  </>
))
