import gql from "graphql-tag"

export const GET_SHOP_SETTINGS = gql`
  query GET_SHOP_SETTINGS {
    shop {
      name
      description
      moneyFormat
      primaryDomain {
        host
        url
      }
      shipsToCountries
      paymentSettings {
        acceptedCardBrands
        countryCode
        currencyCode
        enabledPresentmentCurrencies
        supportedDigitalWallets
      }
    }
  }
`

export const GET_SHOP_PAYMENT_SETTINGS = gql`
  query GET_SHOP_PAYMENT_SETTINGS {
    shop {
      paymentSettings {
        acceptedCardBrands
        countryCode
        currencyCode
        enabledPresentmentCurrencies
        supportedDigitalWallets
      }
    }
  }
`
