import { useApp } from "./useApp"
import { useCore } from "./useCore"

export const useRoutes = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser },
  } = useCore()

  // const linkResolver = (item: any, type = null) => {
  //   if (!type && !item) {
  //     return null
  //   }
  //
  //   const routeType = type ? type : item?.type ? item.type : item?._type ? item._type : item.__typename
  //   const routeURL = item.handle
  //     ? item.handle
  //     : item.shopify?.handle
  //     ? item.shopify?.handle
  //     : item.shopify?.shopifyHandle
  //     ? item.shopify?.shopifyHandle
  //     : item.shopifyHandle
  //     ? item.shopifyHandle
  //     : item.slug
  //     ? item.slug.current || item.slug
  //     : item.uid
  //     ? item.uid
  //     : item.id
  //
  //   const routes = {
  //     article: `${settings.routes.ARTICLE}/${routeURL}`,
  //     articles: `${settings.routes.ARTICLES}`,
  //     cart: `${settings.routes.CART}`,
  //     collection: `${settings.routes.COLLECTION}/${routeURL}`,
  //     default: `${settings.routes.HOMEPAGE}`,
  //     flexiblePage: `${settings.routes.PAGE}/${routeURL}`,
  //     homepage: `${settings.routes.HOMEPAGE}`,
  //     page: `${settings.routes.PAGE}/${routeURL}`,
  //     product: `${settings.routes.PRODUCT}/${routeURL}`,
  //     policy: `${settings.routes.POLICY}/${routeURL}`,
  //     store: `${settings.routes.STORE}/${routeURL}`,
  //   }
  //
  //   return {
  //     title: item?.title || document?.title || null,
  //     type: routeType,
  //     url: routes[routeType] || routes[routeType?.toLowerCase()] || routes[`default`],
  //   }
  // }

  const findRoute = type => {
    const route = Object.entries(routes)?.find(([key]) => type?.toLowerCase()?.replace(`pageaccount`, ``)?.includes(key?.toLowerCase())) || [
      `page`,
      routes[`page`],
    ]

    return {
      type: route[0]?.toLowerCase(),
      url: route[1],
    }
  }

  const linkResolver = (item, itemType = null) => {
    const document = item?.document || item
    const type = itemType ? itemType : document?.type || document?._type || document.__typename
    const title = item?.title || document?.title || null

    if (!document) return {}
    if (type === `navigationSub`) return { url: null, type: `navigationSub`, title }
    if (type === `customLink` || type === `externalLink`) return { url: document?.link || document?.url, external: document?.external, title, type }

    const route = findRoute(type)

    return {
      title,
      type: route?.type || null,
      url: routeResolver({ item: document[route?.type] || document, type: route?.type }),
    }
  }

  const routeResolver = ({ item, type }) => {
    const dynamicTypes = [`product`, `collection`, `flexible`, `generic`, `page`, `article`, `policy`]
    const itemType = type || item?.type || item?._type
    const handle =
      item?.meta?.metaCanonicalURL?.current ||
      item?.slug?.current ||
      item?.shopify?.shopifyHandle ||
      item?.handle ||
      item?.shopifyHandle ||
      item?._id?.replace(`drafts.`, ``).replace(`page`, ``).toLowerCase()
    const parentHandle = item?.parentCollection?.shopify?.shopifyHandle
    const route = findRoute(itemType)

    return `${route?.url}${parentHandle && dynamicTypes?.includes(route?.type?.toLowerCase()) ? `/${parentHandle}` : ``}${
      handle && dynamicTypes?.includes(route?.type?.toLowerCase()) ? `/${handle}` : ``
    }`
  }

  const getUrlParameter = (name: string, location = (isBrowser && window?.location) || null) => new URLSearchParams(location?.search)?.get(name)

  const setUrlParameter = (name = ``, value = ``, location = (isBrowser && window?.location?.pathname) || null) => {
    const regex = new RegExp("([?&])" + name + "=.*?(&|$)", "i")
    const separator = location?.indexOf("?") !== -1 ? "&" : "?"

    return location.match(regex) ? location.replace(regex, "$1" + name + "=" + value + "$2") : `${location}${separator}${name}=${value}`
  }

  return {
    getUrlParameter,
    linkResolver,
    routeResolver,
    setUrlParameter,
  }
}
