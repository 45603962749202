import React from "react"

import { useApp } from "@hooks/useApp"
import { useSettings } from "@hooks/useSettings"

export const withFooter = Component => ({ name = `Footer`, location }) => {
  const {
    invertedTheme,
    config: {
      settings: { routes },
    },
  } = useApp()
  const { organisation } = useSettings()

  const accountHeadline = organisation?.accountHeadline
  const tagline = organisation?.headline?.split(` `)
  const accountPage = location?.pathname?.includes(routes.ACCOUNT)
  const homePage = location?.pathname === routes.HOMEPAGE

  Component.displayName = name
  return <Component accountHeadline={accountHeadline} accountPage={accountPage} homePage={homePage} invertedTheme={invertedTheme} tagline={tagline} />
}
