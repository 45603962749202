import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import { useApp } from "@hooks/useApp"
import { useCustomerAddresses } from "@hooks/useCustomer"
import { useLanguage } from "@hooks/useLanguage"

export const withStoresForm = Component => ({ name = `StoresForm` }) => {
  const {
    config: {
      settings: { params, routes },
    },
    invertedTheme,
  } = useApp()
  const { addresses } = useCustomerAddresses()
  const locales = useLanguage(`stores`)
  const [value, setValue] = useState(``)

  const handleChange = ({ target: { value } }) => setValue(value)

  const handleSubmit = event => {
    event.preventDefault()

    navigate(`${routes.STORE}${value ? `?${params.stores}=${value}` : ``}`)
  }

  useEffect(() => {
    const defaultAddress = addresses?.find(address => address.default)

    if (defaultAddress?.city && !value) {
      setValue(defaultAddress?.city)
    }
  }, [addresses])

  Component.displayName = name
  return <Component handleChange={handleChange} handleSubmit={handleSubmit} invertedTheme={invertedTheme} locales={locales} value={value} />
}
