import { SearchItem } from "@app/components/Search/Item/SearchItem"
import { useShopify } from "@app/hooks/useShopify"

import React from "react"
import { ResultCardProductProps } from "@usereactify/search"

export type ExampleResultCardProductProps = React.ComponentProps<NonNullable<ResultCardProductProps["render"]>>

export const CardProduct: React.FC<ExampleResultCardProductProps> = props => {
  return (
    <div ref={props.itemRef} className="rs__result-card-product">
      <a className="rs__result-card-product__link" onClick={props.handleClick} href={`/products/${props.product.handle}`}>
        {props.product.image && <img className="rs__result-card-product__image" src={props.product.image} width="100%" />}
        <span className="rs__result-card-product__title">{props.product.title}</span>
      </a>
      <span className="rs__result-card-product__price">{props.formattedPrice}</span>
      {props.onSale && (
        <span
          className="rs__result-card-product__price-sale"
          style={{
            textDecoration: "line-through",
          }}
        >
          {props.formattedCompareAtPrice}
        </span>
      )}
    </div>
  )
}

type SearchFormResultsItem = {
  list?: string
  product: any
  setActiveSearch: any
}

type Props = Parameters<NonNullable<React.ComponentProps<typeof Results>["renderResultCardProduct"]>>[0] & SearchFormResultsItem

export const SearchFormResultItem: React.FC<Props> = ({ list, setActiveSearch, product }) => {
  const { adminProductNormaliser } = useShopify()
  return (
    <SearchItem
      key={product.id}
      // collection={collection}
      // index={index}
      // list={collection?.title || `Search`}
      list={list}
      product={adminProductNormaliser(product)}
      verticalSpacing
      width={`1/2 md:1/4`}
      isNav={true}
      onClick={() => setActiveSearch(false, "keepStorage")}
      // hoverImageIndex={collectionOption?.hoverImageIndex}
    />
  )
}
