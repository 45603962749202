import React from "react"

import { LineItem } from "../../../../types"

import { QuantityMinus, QuantityPlus, Quantity, QuantityRow, QuantityAmount, QuantityButton } from "../CartStyles"

interface Props {
  handleChange: Function
  lineItem: LineItem
}

export const CartItemQuantity = ({ lineItem, handleChange }: Props) => (
  <Quantity>
    <QuantityRow>
      <QuantityButton disabled={lineItem.quantity <= 1} onClick={() => handleChange(lineItem.quantity - 1)}>
        <QuantityMinus />
      </QuantityButton>
      <QuantityAmount>{lineItem.quantity}</QuantityAmount>
      <QuantityButton
        disabled={lineItem.merchandise.quantityAvailable ? lineItem.quantity >= lineItem.merchandise?.quantityAvailable : false}
        onClick={() => handleChange(lineItem.quantity + 1)}
      >
        <QuantityPlus />
      </QuantityButton>
    </QuantityRow>
  </Quantity>
)
