import React from "react"

import { HiddenSpan } from "../Styled/Accessibility"
import { StyledBrandLink, StyledLogo } from "./BrandStyles"

interface Props {
  title: string
}

export const Brand = ({ title }: Props) => (
  <StyledBrandLink to={`/`}>
    <StyledLogo title={title} />
    <HiddenSpan>{title}</HiddenSpan>
  </StyledBrandLink>
)
