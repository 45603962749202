import React from "react"

import { withPopup } from "./withPopup"
import { Outer, Inner, Bg, Content, Close, StyledIcon } from "./PopupStyles"

export interface Props {
  active: boolean
  children: any
  locales: any
  noClose: boolean
  setActive: any
  transparent: boolean
  width: string
}

export const Popup = withPopup(({ active, children, locales, noClose, setActive, transparent, width }: Props) => (
  <Outer active={active} setActive={setActive}>
    <Bg active={active} onClick={() => setActive(false)} />

    <Inner active={active} noOverflow={noClose} width={width}>
      <Content active={active} transparent={transparent}>
        {!noClose ? (
          <Close onClick={() => setActive(false)} title={locales.close}>
            <StyledIcon />
          </Close>
        ) : null}

        {children}
      </Content>
    </Inner>
  </Outer>
))
