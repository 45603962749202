import React from "react"
import { useShopify } from "@hooks/useShopify"

interface Props {
  amount: any
}

export const Money = ({ amount }: Props) => {
  const { formatMoney } = useShopify()

  return <>{formatMoney(amount?.amount, amount?.currencyCode, amount?.amount === Math.floor(amount?.amount) ? "0,0" : "0,0.00") || `—`}</>
}
