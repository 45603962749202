import styled from "@emotion/styled"
import tw from "twin.macro"

import { H2, H4, I } from "../Styled/Text"
import { Container } from "../Styled/Container"

export const Section = styled.section`
  ${tw`relative z-40`}
`
export const StyledHeader = styled.header`
  ${tw`fixed z-20 w-full transition-all duration-200`}
  ${props => (props.visible ? `top: -${props.offset}px;` : `top: 0px;`)}
  ${props => (props.inverted && !props.scrolled ? tw`bg-transparent` : tw`bg-light`)}
`
export const StyledContainer = styled(Container)`
  ${tw`relative z-10 py-1-5 md:py-1-2 flex items-center justify-between`}
  ${props => (props.inverted && !props.scrolled ? tw`text-light` : tw`text-dark`)}
`

export const FooterWrapper = tw.footer`flex-shrink-0 md:mt-4`
const FooterSection = styled.section`
  ${props => (props.inverted ? tw`border-transparent` : tw`border-grey-DEFAULT`)}
`
export const SectionTop = tw(FooterSection)`md:border-t mt-5-6 md:mt-12`
export const SectionBottom = tw(FooterSection)`mb-10 md:mb-0 border-t`
export const ContainerTop = tw(Container)`flex flex-col-reverse md:flex-row items-start justify-between max-w-lg md:pt-4 pb-4 md:pb-8`
export const ContainerBottom = tw(Container)`flex items-center justify-between py-1-2 max-w-lg`
export const Links = tw.div`w-full md:w-3/4`
export const Subscribe = tw.div`w-full md:w-1/4 lg:pl-0-6`
export const StyledH2 = tw(H2)`hidden md:block text-center font-sans font-normal leading-relaxed uppercase`
export const StyledH4 = tw(H4)`md:hidden text-center font-sans font-normal leading-relaxed uppercase`
export const StyledI = tw(I)`font-display font-normal`
export const StyledTagline = tw(H2)`text-lg md:text-lg hidden md:block text-center font-sans font-normal leading-relaxed uppercase`

export const Wrapper = styled.div`
  flex: 1 0 auto;
`
