import styled from "@emotion/styled"
import tw from "twin.macro"

export const Styles = {
  H1: tw`text-xl md:text-3xl font-display font-normal tracking-tightest leading-normal break-words`,
  H2: tw`text-xl md:text-2xl font-display font-normal tracking-tighter leading-normal break-words`,
  H3: tw`text-xl font-display font-normal tracking-tense leading-normal break-words`,
  H4: tw`text-lg font-display font-normal tracking-normal leading-relax`,
  H5: tw`text-base md:text-md font-sans font-light tracking-tight leading-loose uppercase`,
  H6: tw`text-base font-body font-normal tracking-relaxed leading-looser`,
  P: tw`text-sm font-body font-normal tracking-wide leading-looser`,
  Small: tw`text-xs font-body font-normal tracking-relaxed leading-loosest`,
  Label: tw`text-xxs font-body font-medium tracking-wider leading-normal uppercase`,
}

export const H1 = styled.h1`
  ${Styles.H1}
`
export const H2 = styled.h2`
  ${Styles.H2}
`
export const H3 = styled.h3`
  ${Styles.H3}
`
export const H4 = styled.h4`
  ${Styles.H4}
`
export const H5 = styled.h5`
  ${Styles.H5}
`
export const H6 = styled.h6`
  ${Styles.H6}
`

export const P = styled.p`
  ${Styles.P}
`
export const Small = styled.p`
  ${Styles.Small}
`
export const Label = styled.p`
  ${Styles.Label}
`
export const I = tw.span`italic`
