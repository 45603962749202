import { useCallback } from "react"
import { getGatsbyImageData } from "gatsby-source-sanity"

import { useApp } from "./useApp"
import { useCore } from "./useCore"

export const useImage = () => {
  const {
    config: {
      services: { sanity },
    },
  } = useApp()
  const {
    helpers: { handleize },
  } = useCore()

  const filename = ({ alt, asset }) => (asset ? `${handleize(asset?.originalFilename?.split(`.`)?.[0] || alt)}.${asset?.extension}` : null)

  const webPImage = useCallback(src => src?.split(`fm=webp`).join(``), [])

  const withFilename = useCallback(
    (fluidImage = {}, name = `image.jpg`, properties = [`src`, `srcWebp`, `srcSet`, `srcSetWebp`]) => ({
      ...fluidImage,
      ...properties?.reduce(
        (o, key) => ({
          ...o,
          [key]: webPImage(fluidImage[key]?.split(`?`)?.join(`/${name}?`)),
        }),
        {}
      ),
    }),
    [webPImage]
  )

  const getFluidImage = (image, options = {}) => {
    const fluidImage = image?.asset?.id
      ? {
          ...withFilename(getGatsbyImageData(image, options || {}, sanity)?.images?.fallback, filename(image)),
        }
      : null
    const noUndifiedImage = {
      ...fluidImage,
      src: fluidImage?.src?.replace("undified", process.env.GATSBY_SANITY_DEFAULT_DATASET),
      srcSet: fluidImage?.srcSet?.replace("undified", process.env.GATSBY_SANITY_DEFAULT_DATASET),
    }
    return noUndifiedImage
  }

  const getReponsiveImage = (image, options = null) => ({
    desktop: getFluidImage(image?.desktop, options?.desktop || options || {}),
    mobile: getFluidImage(image?.mobile?.asset ? image?.mobile : image?.desktop, options?.mobile || options?.desktop || options || {}),
  })

  return { getFluidImage, getReponsiveImage }
}
