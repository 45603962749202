import React from "react"

import { withCartItemAttributes } from "./withCartItemAttributes"
import { CartItemPrice } from "./CartItemPrice"
import { P } from "../../Styled/Text"
import { LineItemTitle, Price, Attribute, LineItemDetails } from "../CartStyles"

interface Props {
  drawer: boolean
  grouped: Array<any>
  locales: any
  timeline: any
}

export const CartItemAttributes = withCartItemAttributes(({ drawer, grouped, locales, timeline }: Props) =>
  drawer ? (
    grouped && grouped.length ? (
      grouped.map(({ title: groupedTitle, variant: groupedVariant }) => (
        <LineItemDetails key={groupedTitle} drawer={drawer}>
          <P>
            {groupedTitle}:&nbsp;
            <CartItemPrice money={groupedVariant?.priceV2} />
          </P>
        </LineItemDetails>
      ))
    ) : null
  ) : (
    <>
      {timeline && (
        <LineItemDetails>
          <LineItemTitle>
            <P>{locales.madeToOrder}</P>
            <Attribute>{`${locales.deliveryTimeline} ${timeline}`}</Attribute>
          </LineItemTitle>
        </LineItemDetails>
      )}

      {grouped?.map(({ title: groupedTitle, variant: groupedVariant }) => (
        <LineItemDetails key={groupedTitle}>
          <LineItemTitle>
            <P>{groupedTitle}</P>
            <Attribute>{groupedVariant?.product?.description}</Attribute>
          </LineItemTitle>

          <Price>
            <P>&nbsp;</P>
            <P>
              <CartItemPrice money={groupedVariant?.priceV2} />
            </P>
          </Price>
        </LineItemDetails>
      ))}
    </>
  )
)
