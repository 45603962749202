import styled from "@emotion/styled"
import tw from "twin.macro"

export const Wrapper = styled.div`
  ${tw`min-h-3 flex justify-center items-center z-20 relative p-0-4`}
  ${props => `background: ${props.bgColour};`}
`

export const Title = styled.p`
  ${tw`text-center uppercase tracking-wider text-center text-xxs font-medium `}
`
