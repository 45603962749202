import gql from "graphql-tag"

export const CUSTOMER_FRAGMENT = gql`
  fragment CustomerFragment on Customer {
    id
    firstName
    lastName
    email
    phone
    acceptsMarketing
    tags
    defaultAddress {
      id
    }
  }
`
