import React from "react"
import { useCheckoutContext } from "@hooks/useCheckout"
import { useLanguage } from "@hooks/useLanguage"
import { useRoutes } from "@hooks/useRoutes"

export const withCartFormTotals = Component => ({ name = `CartFormTotals`, drawer, setActiveCart, setShowPopup, customPaymentEnabled }) => {
  const { checkout } = useCheckoutContext()

  const locales = { ...useLanguage(`cart`), ...useLanguage(`payment`) }
  const { routeResolver } = useRoutes()

  const checkCustomPaymentEligibility = checkout => {
    if (!(checkout?.lineItems?.length > 0) || !customPaymentEnabled) {
      return false
    }
    for (const lineItem of checkout.lineItems) {
      const tags = lineItem.variant?.product?.tags
      if (tags && tags.filter(tag => tag.includes("payment-plan:")).length > 0) {
        return true
      }
    }

    return false
  }

  const giftCardTotal =
    checkout?.appliedGiftCards?.reduce(
      (total, appliedGiftCard, i) => (i ? parseInt(appliedGiftCard?.amountUsedV2?.amount) + total : parseInt(appliedGiftCard?.amountUsedV2?.amount)),
      0
    ) || 0

  const discountsTotal =
    checkout?.discountApplications?.length > 0 ? checkout?.lineItemsSubtotalPrice?.amount - checkout?.paymentDue?.amount - giftCardTotal : 0
  const customerPayments = checkCustomPaymentEligibility(checkout)
  const url = routeResolver({ type: `cart` })

  Component.displayName = name
  return (
    <Component
      checkout={checkout}
      customerPayments={customerPayments}
      drawer={drawer}
      discountsTotal={discountsTotal}
      giftCardTotal={giftCardTotal}
      locales={locales}
      setActiveCart={setActiveCart}
      setShowPopup={setShowPopup}
      url={url}
    />
  )
}
