import React, { createContext, useState } from "react"

export const WishlistContext = createContext({})

export const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState([])

  const values = {
    wishlist,
    setWishlist,
  }

  return <WishlistContext.Provider value={{ ...values }}>{children}</WishlistContext.Provider>
}
