import React from "react"

import { withGeoLocationPopup } from "./withGeoLocationPopup"
import { Popup } from "../Modals/Popup/Popup"
import {
  Button,
  PopupContainer,
  RedirectButton,
  RedirectLink,
  Text,
  Title,
  RedirectButtonContainer,
  Close,
  StyledIcon,
} from "./GeoLocationPopupStyles"

interface Props {
  active: boolean
  // setActive: boolean
  isAu: boolean
  redirectUrl: string
  handleClose: () => void
}

export const GeoLocationPopup = withGeoLocationPopup(({ active, handleClose, redirectUrl, isAu }: Props) => (
  <>
    <Popup active={active} noClose setActive={() => {}} transparent width={`lg`}>
      <PopupContainer>
        <Close onClick={handleClose}>
          <StyledIcon />
        </Close>
        <Title>CHOOSE YOUR STORE</Title>
        <Text>Please select the correct store you'd like to browse:</Text>
        <RedirectButtonContainer>
          {isAu && (
            <>
              <RedirectLink to={redirectUrl} title={`Go to store`}>
                United States
              </RedirectLink>
              <RedirectButton onClick={handleClose}>Rest of the world</RedirectButton>
            </>
          )}
          {!isAu && (
            <>
              <RedirectButton onClick={handleClose}>United States</RedirectButton>
              <RedirectLink to={redirectUrl} title={`Go to store`}>
                Rest of the world
              </RedirectLink>
            </>
          )}
        </RedirectButtonContainer>
      </PopupContainer>
    </Popup>
  </>
))
