import React, { createContext, useEffect, useState } from "react"
import TagManager from "react-gtm-module"
// TODO: replace with react-intersection-observer
import VisibilitySensor from "react-visibility-sensor"

import { useSettings } from "../hooks/useSettings"

export const TrackingContext = createContext({})

export const TrackingProvider = ({ children }) => {
  const [tracked, setTracked] = useState(false)
  const { tracking } = useSettings()

  const values = {
    gtm: TagManager,
    tracked,
    setTracked,
    VisibilitySensor: VisibilitySensor,
  }

  useEffect(() => {
    if (!tracked && tracking?.googleTagManagerId) {
      setTracked(true)
      TagManager.initialize({
        gtmId: tracking?.googleTagManagerId,
      })
    }
  }, [tracked, tracking?.googleTagManagerId])

  return <TrackingContext.Provider value={{ ...values }}>{children}</TrackingContext.Provider>
}

export const withTracking = Component => props => (
  <TrackingContext.Consumer>{values => <Component {...props} layout={values} />}</TrackingContext.Consumer>
)
