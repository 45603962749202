import { useStaticQuery, graphql } from "gatsby"

import { useSanity } from "@hooks/useSanity"
import { useLocation } from "./useLocation"

export const useLanguage = path => {
  const { textNormaliser } = useSanity()

  const { data } = useStaticQuery(
    graphql`
      query {
        data: sanityLabels {
          _rawAccessibility(resolveReferences: { maxDepth: 10 })
          _rawAccount(resolveReferences: { maxDepth: 10 })
          _rawBlog(resolveReferences: { maxDepth: 10 })
          _rawBrides(resolveReferences: { maxDepth: 10 })
          _rawCart(resolveReferences: { maxDepth: 10 })
          _rawCollection(resolveReferences: { maxDepth: 10 })
          _rawForm(resolveReferences: { maxDepth: 10 })
          _rawGiftcard(resolveReferences: { maxDepth: 10 })
          _rawPassword(resolveReferences: { maxDepth: 10 })
          _rawProducts(resolveReferences: { maxDepth: 10 })
          _rawSearch(resolveReferences: { maxDepth: 10 })
          _rawStores(resolveReferences: { maxDepth: 10 })
          _rawWishlist(resolveReferences: { maxDepth: 10 })
          _rawPayment(resolveReferences: { maxDepth: 10 })
        }
      }
    `
  )

  const { location } = useLocation()
  const locationLanguage = location === "AU" ? "enAu" : "enUs"

  const languageNormaliser = labels => {
    const obj = {}
    const filteredObj = Object.entries(labels).filter(([key, value]) => key !== `_type` && value)

    filteredObj.map(
      ([key, value]) =>
        (obj[key?.replace("_raw", "")?.charAt(0)?.toLowerCase() + key?.replace("_raw", "")?.slice(1)] =
          value._type === `localeString` ? (value.enUs ? textNormaliser(value, locationLanguage) : textNormaliser(value)) : languageNormaliser(value))
    )

    return obj
  }
  const language = languageNormaliser(data)
  return !path ? language : language[path] || `No language string for "${path}"`
}
