import * as Sentry from "@sentry/gatsby"

export const useSentry = () => {
  const identifyUser = customer => {
    if (customer?.email) {
      Sentry.setUser({ email: customer.email })
    }
  }

  const sendSentryEvent = eventData => {
    const formattedEventData: Sentry.Event = {
      message: eventData.message,
      level: eventData.level,
      tags: {
        component: eventData.tag,
        shopifyShopName: eventData.shopifyStore,
      },
      extra: {
        description: eventData.description,
        responsePayload: eventData.responsePayload,
      },
    }

    Sentry.captureEvent(formattedEventData)

    if (eventData.error) {
      Sentry.captureException(eventData.error)
    }
  }

  return { identifyUser, sendSentryEvent }
}
