import gql from "graphql-tag"

import { ORDER_FRAGMENT } from "../fragments/orderFragment"

export const GET_ORDER = gql`
  query GET_ORDER($countryCode: CountryCode!, $id: ID!) @inContext(country: $countryCode) {
    node(id: $id) {
      ... on Order {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`
