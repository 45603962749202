import React from "react"

import { useApp } from "@hooks/useApp"

export const withLayoutGlobal = Component => ({ name = `LayoutGlobal` }) => {
  const { invertedTheme } = useApp()

  Component.displayName = name
  return <Component invertedTheme={invertedTheme} />
}
