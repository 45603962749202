import { GatsbyImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import tw from "twin.macro"

export const ImageWrapper = tw.div`relative`
export const ImageRatio = styled.div`
  ${tw`bg-grey-lightest`}
  padding-bottom: ${props => (props.ratio.split("/")[1] / props.ratio.split("/")[0]) * 100}%;
`
export const PreloadImage = tw.img`hidden w-0-1 h-0-1`
export const StyledGatsbyImage = styled(GatsbyImage)`
  ${props => (props.small ? tw`max-w-30` : tw`w-full max-w-full`)}
  ${props => (props.cover ? tw`absolute inset-0 object-cover object-center h-full` : ``)}
  ${props =>
    props.gradient
      ? `
      &::after {
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(9, 9, 9, 0.5) 100%);
        display: block;
        position: relative;
        margin-top: -150px;
        height: 150px;
        width: 100%;
        content: '';
      }
  `
      : ``}
`
export const StyledImage = styled.img`
  ${props => (props.small ? tw`max-w-30` : tw`w-full max-w-full`)}
  ${props => (props.maxV ? tw`w-auto max-w-full-vw max-h-full-vh p-1-6` : ``)}
  ${props => (props.preloading ? tw`animate-placeholder` : ``)}
  ${props => (props.cover ? tw`absolute inset-0 object-cover object-center h-full` : ``)}
  ${props => (props.inline ? tw`block mb-1-6` : ``)}
`
