import gql from "graphql-tag"

import { COLLECTION_FRAGMENT } from "../fragments/collectionFragment"
import { PRODUCT_FRAGMENT } from "../fragments/productFragment"

export const GET_COLLECTION = gql`
  query($handle: String!) {
    collection: collectionByHandle(handle: $handle) {
      ...CollectionFragment
    }
  }
  ${COLLECTION_FRAGMENT}
`

export const GET_COLLECTION_PRODUCT_REFERENCES = gql`
  query($countryCode: CountryCode!, $handle: String!, $firstProducts: Int) @inContext(country: $countryCode) {
    collection: collectionByHandle(handle: $handle) {
      ...CollectionFragment
      products(first: $firstProducts) {
        edges {
          node {
            id
            handle
          }
        }
      }
    }
  }
  ${COLLECTION_FRAGMENT}
`

export const GET_COLLECTION_PRODUCT_COMPLETE = gql`
  query(
    $countryCode: CountryCode!
    $handle: String!
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $metafieldIdentifiers: [HasMetafieldsIdentifier!]!
    $firstProducts: Int
    $firstVariants: Int
  ) @inContext(country: $countryCode) {
    collection: collectionByHandle(handle: $handle) {
      ...CollectionFragment
      products(first: $firstProducts) {
        edges {
          node {
            ...ProductFragment
          }
        }
      }
    }
  }
  ${COLLECTION_FRAGMENT}
  ${PRODUCT_FRAGMENT}
`

export const GET_COLLECTIONS_BY_HANDLE = handles => gql`
  query(
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $metafieldIdentifiers: [HasMetafieldsIdentifier!]!
    $firstProducts: Int
    $firstVariants: Int

  ) {
    ${
      handles === null || handles === void 0
        ? void 0
        : handles.map(
            handle => `
      collection${handle.replace(/-/g, "")}: collectionByHandle(handle: "${handle}") {
        ...CollectionFragment
        products(first: $firstProducts) {
          edges {
            node {
              ...ProductFragment
            }
          }
        }
      }
    `
          )
    }
  }
  ${COLLECTION_FRAGMENT}
  ${PRODUCT_FRAGMENT}
`
