import React from "react"

import { useApp } from "@hooks/useApp"
import { useLanguage } from "@hooks/useLanguage"
import { useRoutes } from "@hooks/useRoutes"

export const withNavigationFooterPopup = Component => ({ name = `NavigationFooterPopup`, active, setActive }) => {
  const {
    config: {
      settings: { products },
    },
  } = useApp()
  const locales = useLanguage(`products`)
  const { linkResolver } = useRoutes()

  Component.displayName = name
  return <Component active={active} linkResolver={linkResolver} locales={locales} products={products} setActive={setActive} />
}
