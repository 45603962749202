import React, { useState } from "react"

export const withNavigationMainDropdown = Component => ({ name = `NavigationMainDropdown`, active, items, index, link, setActive, title }) => {
  const [activeItem, setActiveItem] = useState(false)

  Component.displayName = name
  return (
    <Component
      active={active}
      activeItem={activeItem}
      items={items}
      index={index}
      link={link}
      setActive={setActive}
      setActiveItem={setActiveItem}
      title={title}
    />
  )
}
