import gql from "graphql-tag"

import { VARIANT_FRAGMENT } from "./variantFragment"

export const VARIANT_WITH_PRODUCT_FRAGMENT = gql`
  fragment VariantWithProductFragment on ProductVariant {
    id
    image {
      altText
      src
    }
    price {
      amount
      currencyCode
    }
    priceV2 {
      amount
      currencyCode
    }
    sku
    title
    compareAtPrice {
      amount
      currencyCode
    }
    weight
    weightUnit
    availableForSale
    currentlyNotInStock
    quantityAvailable
    requiresShipping
    selectedOptions {
      name
      value
    }
    product {
      description
      title
      handle
      id
      productType
      tags
      vendor
    }
  }
`
