import styled from "@emotion/styled"
import tw from "twin.macro"

import { Container } from "../../Styled/Container"

export const Wrapper = styled.div`
  ${tw`absolute inset-x-0 w-full top-full z-10 py-1-2 transform transition-all duration-200 ease-cubic`}
  ${props => (props.active ? tw`duration-500 translate-y-0` : tw`-translate-y-full`)}
  ${props => (props.inverted ? tw`bg-transparent` : tw`bg-light`)}
`
export const InputWrapper = styled.div`
  ${tw`flex items-center w-full`}
  .SearchContainer {
    ${tw`w-full`}
    input {
      ${tw`block w-full border-0 bg-transparent`}
    }
    > div > div > div {
      ${tw`pr-0 top-0-9`}
    }
  }
`
export const StyledContainer = styled(Container)`
  ${tw`transform transition-all duration-200`}
  ${props => (props.active ? tw`translate-y-0 duration-500 delay-200` : tw`-translate-y-12`)} ${props =>
    props.active ? tw`opacity-100` : tw`opacity-0`}
`
export const Suggestions = tw.section`mt-1-2 mb-0-6`
export const Title = tw.p`text-sm leading-loose tracking-relaxed mr-1 flex-1`
