import React, { createContext, useState, useEffect, useCallback } from "react"

import { useCore } from "@hooks/useCore"
import { useGlobal } from "@hooks/useGlobal"

export const LocationContext = createContext(null)

export const LocationProvider = ({ children, config }) => {
  const {
    helpers: { storage },
  } = useCore()
  const { handleGlobalGroupReady } = useGlobal()

  const { keys } = config?.settings
  const { location: locationService } = config?.services
  const defaultStore = config?.services?.shopify?.defaultShopName
  const defaultStoreDomain = config?.stores[defaultStore].shopDomain
  const shopifyStore = storage.get(keys?.shopify) || defaultStore
  const shopifyStoreDomain = config?.stores[shopifyStore]?.shopDomain || defaultStoreDomain

  const [settings, setSettings] = useState({
    country: storage.get(keys?.country) || "",
    location: storage.get(keys?.location) || "",
    locations: Object.values(config?.stores).map(store => store?.siteLocation),
    locating: !storage.get(keys?.country)?.length,
    storeConfig: config?.stores[shopifyStore] || config?.stores[defaultStore],
    userLocation: storage.get(keys?.userLocation) || "",
    visitor: !config?.stores[shopifyStore]?.siteCountries?.includes(storage.get(keys?.country) || ""),
    shopifyStore,
    shopifyStoreDomain,
  })

  const updateLocation = useCallback(
    (countryCode, updateCountry = false) => {
      const shopifyStore = Object.values(config?.stores).find(store => store?.siteCountries.includes(countryCode))?.shopName || defaultStore
      const shopifyStoreDomain =
        Object.values(config?.stores).find(store => store?.siteCountries.includes(countryCode))?.shopDomain || defaultStoreDomain

      setSettings(prevState => ({
        ...prevState,
        country: updateCountry ? countryCode : settings?.country,
        location: countryCode,
        shopifyStore: shopifyStore,
        shopifyStoreDomain: shopifyStoreDomain,
        storeConfig: config?.stores[shopifyStore],
        userLocation: updateCountry ? countryCode : settings?.userLocation,
        visitor: locationService?.forcelocation ? !config?.stores[shopifyStore]?.siteCountries?.includes(settings?.country) : false,
        locating: false,
      }))
    },
    [settings]
  )

  // useEffect(() => {
  //   if (!settings?.userLocation) {
  //     fetch(locationService?.serviceUrl)
  //       .then(res => res.json())
  //       .then(result => updateLocation(result[locationService?.countryFieldKey] || config?.stores[defaultStore]?.siteLocation, true))
  //       .catch(() => updateLocation(config?.stores[defaultStore]?.siteLocation, true))
  //   }
  // }, [settings?.userLocation])

  useEffect(() => {
    handleGlobalGroupReady("location")
  }, [defaultStore])

  useEffect(() => {
    async function fetchLocation() {
      if (settings?.userLocation) {
        const country = config?.stores[defaultStore]?.siteLocation
        storage.set(keys.country, settings.country)
        storage.set(keys?.location, settings?.userLocation)
        storage.set(keys?.shopify, defaultStore)
      } else {
        fetch(locationService?.serviceUrl)
          .then(res => res.json())
          .then(result => updateLocation(result[locationService?.countryFieldKey] || config?.stores[defaultStore]?.siteLocation, true))
          .catch(() => updateLocation(config?.stores[defaultStore]?.siteLocation, true))
      }
    }
    fetchLocation()
  }, [settings, keys, storage, updateLocation])

  return <LocationContext.Provider value={{ updateLocation, ...settings }}>{children}</LocationContext.Provider>
}

export const withLocation = Component => props => (
  <LocationContext.Consumer>
    {({ updateLocation, ...settings }) => <Component {...props} {...settings} updateLocation={updateLocation} />}
  </LocationContext.Consumer>
)
