import React, { useEffect } from "react"

import { useAnalytics } from "@hooks/useAnalytics"
import { useApp } from "@hooks/useApp"
import { useCheckoutContext } from "@hooks/useCheckout"
import { useMaintenance } from "@hooks/useMaintenance"
import { useShopifyProduct } from "@hooks/useShopify"

export const withLayout = Component => ({ name = `Layout`, children, location, data, ...props }) => {
  const { trackEvent } = useAnalytics()
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const {
    checkout: { currencyCode },
  } = useCheckoutContext()
  const { active, authorised } = useMaintenance(location)
  const { selectProduct } = useShopifyProduct()

  const onedaypay = location?.pathname.match(/^\/onedaypay\/:orderId\/charge/) || location?.pathname.match(/^\/onedaypay\/:orderId\/checkout/)

  useEffect(() => {
    selectProduct(data?.product, location?.pathname)
  }, [location?.pathname, currencyCode, data?.product, selectProduct])

  useEffect(() => {
    trackEvent()
  }, [location?.pathname])

  Component.displayName = name
  return (
    <Component {...props} active={active} authorised={authorised} data={data} location={location} onedaypay={onedaypay} routes={routes}>
      {children}
    </Component>
  )
}
