import React from "react"

import { withCartDrawer } from "./withCartDrawer"
import { CartDrawerCount } from "./CartDrawerCount"
import { CartItem } from "../Item/CartItem"
import { CartFormTotals } from "../Form/CartFormTotals"
import { CustomerPaymentsInformationPopup } from "../../Customer/Payments/Popup/CustomerPaymentsInformationPopup"
import { Drawer } from "../../Modals/Drawer/Drawer"
import { H5 } from "../../Styled/Text"
import { Counter, Continue, Footer, DrawerLineItems, DrawerWrapper, StyledSmallButton, StyledTextButton } from "../CartStyles"

interface Props {
  activeCart: boolean
  checkout: any
  count: number
  customPaymentDescription: string
  items: Array<any>
  getGroupedItems: Function
  locales: any
  onedaypayActive: boolean
  setActiveCart: Function
  setShowPopup: Function
  showPopup: Function
}

export const CartDrawer = withCartDrawer(
  ({
    activeCart,
    checkout,
    count,
    customPaymentDescription,
    items,
    getGroupedItems,
    locales,
    onedaypayActive,
    setActiveCart,
    setShowPopup,
    showPopup,
  }: Props) => {
    return (
      <>
        <Drawer active={activeCart} setActive={setActiveCart} right>
          <Counter>
            <CartDrawerCount />
          </Counter>

          <DrawerWrapper count={count > 0} drawer>
            {count > 0 ? (
              <>
                <DrawerLineItems>
                  {items.map(lineItem => (
                    <CartItem
                      key={lineItem?.id?.toString()}
                      drawer
                      grouped={getGroupedItems(lineItem, checkout?.lines)}
                      lineItem={lineItem}
                      setActiveCart={setActiveCart}
                      setShowPopup={setShowPopup}
                      customPaymentEnabled={onedaypayActive}
                    />
                  ))}
                </DrawerLineItems>

                <Footer>
                  <CartFormTotals drawer setActiveCart={setActiveCart} setShowPopup={setShowPopup} customPaymentEnabled={onedaypayActive} />

                  <Continue>
                    <StyledTextButton onClick={() => setActiveCart(false)}>{locales.continueButton}</StyledTextButton>
                  </Continue>
                </Footer>
              </>
            ) : (
              <>
                <H5 as={`p`}>{locales.emptyTitle}</H5>
                <StyledSmallButton drawer onClick={() => setActiveCart(false)}>
                  {locales.emptyButton}
                </StyledSmallButton>
              </>
            )}
          </DrawerWrapper>
        </Drawer>
        <CustomerPaymentsInformationPopup active={showPopup} setActive={setShowPopup} content={customPaymentDescription} />
      </>
    )
  }
)
