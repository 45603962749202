import React from "react"

// import { SearchComponents } from "@hooks/useSearch"
import { Title } from "../NavigationSearchStyles"
import { CloseButton, Icon } from "../../../Search/SearchStyles"
import { SearchWidget } from "@app/components/Navigation/Search/Form/SearchWidget"
import { useSearch } from "@usereactify/search"

interface Props {
  active: boolean
  config: object
  setActive: any
  value: string
}

export const NavigationSearchForm = ({ active, setActive }: Props) => {
  const { searchTerm } = useSearch()
  return (
    <>
      <Title>Search:</Title>
      <SearchWidget />
      {/* <SearchComponents.DataSearch {...config} className={`SearchContainer`} componentId={`q`} /> */}
      <CloseButton active={active} hidden={!searchTerm?.length} onClick={() => setActive(!active)} type={`button`}>
        <Icon />
      </CloseButton>
    </>
  )
}
