import gql from "graphql-tag"

import { MAILING_ADDRESS_FRAGMENT } from "../fragments/mailingAddressFragment"
import { CUSTOMER_USER_ERROR_FRAGMENT } from "../fragments/customerUserErrorFragment"

export const CUSTOMER_ACCESS_TOKEN_CREATE = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${CUSTOMER_USER_ERROR_FRAGMENT}
`

export const CUSTOMER_ACCESS_TOKEN_CREATE_MULTIPASS = gql`
  mutation customerAccessTokenCreateWithMultipass($multipassToken: String!) {
    customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${CUSTOMER_USER_ERROR_FRAGMENT}
`

export const CUSTOMER_CREATE = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${CUSTOMER_USER_ERROR_FRAGMENT}
`

export const CUSTOMER_UPDATE = gql`
  mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
    customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
      customer {
        id
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${CUSTOMER_USER_ERROR_FRAGMENT}
`

export const CUSTOMER_ADDRESS_CREATE = gql`
  mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
    customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
      customerAddress {
        ...MailingAddressFragment
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${MAILING_ADDRESS_FRAGMENT}
  ${CUSTOMER_USER_ERROR_FRAGMENT}
`

export const CUSTOMER_ADDRESS_DELETE = gql`
  mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
    customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
      deletedCustomerAddressId
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${CUSTOMER_USER_ERROR_FRAGMENT}
`

export const CUSTOMER_ADDRESS_UPDATE = gql`
  mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
    customerAddressUpdate(customerAccessToken: $customerAccessToken, id: $id, address: $address) {
      customerAddress {
        ...MailingAddressFragment
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${MAILING_ADDRESS_FRAGMENT}
  ${CUSTOMER_USER_ERROR_FRAGMENT}
`

export const CUSTOMER_DEFAULT_ADDRESS_UPDATE = gql`
  mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!) {
    customerDefaultAddressUpdate(customerAccessToken: $customerAccessToken, addressId: $addressId) {
      customer {
        id
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${CUSTOMER_USER_ERROR_FRAGMENT}
`

export const CUSTOMER_RECOVER = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${CUSTOMER_USER_ERROR_FRAGMENT}
`

export const CUSTOMER_RESET = gql`
  mutation customerReset($id: ID!, $input: CustomerResetInput!) {
    customerReset(id: $id, input: $input) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${CUSTOMER_USER_ERROR_FRAGMENT}
`

export const CUSTOMER_RESET_BY_URL = gql`
  mutation customerResetByUrl($resetUrl: URL!, $password: String!) {
    customerResetByUrl(resetUrl: $resetUrl, password: $password) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${CUSTOMER_USER_ERROR_FRAGMENT}
`

export const CUSTOMER_ACTIVATE = gql`
  mutation customerActivate($id: ID!, $input: CustomerActivateInput!) {
    customerActivate(id: $id, input: $input) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${CUSTOMER_USER_ERROR_FRAGMENT}
`

export const CUSTOMER_ACTIVATE_BY_URL = gql`
  mutation customerActivateByUrl($activationUrl: URL!, $password: String!) {
    customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${CUSTOMER_USER_ERROR_FRAGMENT}
`
