import styled from "@emotion/styled"
import tw from "twin.macro"

export const PrimaryButton = styled.button`
  ${tw`bg-grey-DEFAULT border border-grey-DEFAULT text-sm px-2 py-1-2 font-body tracking-relaxed leading-normal text-center appearance-none select-none hover:bg-grey-mid hover:border-grey-mid focus:outline-none transition-all duration-200`}
  ${props => (props.disabled ? tw`text-grey-darker pointer-events-none` : tw`text-dark`)}
  ${props => (props.full ? tw`block w-full` : ``)}
`
export const SecondaryButton = tw(PrimaryButton)`hover:bg-light hover:border-light`
export const TertiaryButton = tw(PrimaryButton)`bg-transparent border-light text-light hover:border-light hover:bg-light hover:text-dark`
export const SmallButton = tw.button`bg-transparent text-dark text-sm px-2 py-0-8 border border-grey-DEFAULT font-body tracking-relaxed leading-normal text-center hover:bg-grey-DEFAULT appearance-none focus:outline-none transition-all duration-200`
export const TextButton = styled.button`
  ${tw`bg-transparent text-sm px-0 py-0-8 border-0 font-body tracking-relaxed leading-normal text-center hover:underline appearance-none focus:outline-none transition-all duration-200`}
  ${props => (props.disabled ? tw`text-grey-darkest pointer-events-none` : tw`text-dark`)}
  ${props => (props.small ? tw`text-xs` : ``)}
  ${props => (props.underlined ? tw`underline hover:no-underline` : ``)}
`
