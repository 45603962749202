import React, { useEffect, useState } from "react"

import { useCart } from "@hooks/useCart"
import { useCheckout, useCheckoutContext } from "@hooks/useCheckout"
import { useCustomerDetails } from "@hooks/useCustomer"
import { useLanguage } from "@hooks/useLanguage"
import { useLocation } from "@hooks/useLocation"
import { usePaymentData } from "@hooks/usePayments"

export const withCartDrawer = Component => ({ name = `CartDrawer`, activeCart, setActiveCart }) => {
  const [showPopup, setShowPopup] = useState(false)
  const [onedaypayActive, setOnedaypayActive] = useState(false)
  const { clearCart, excludeGroupedAndWrappingItems, getGroupedItems } = useCart()
  const { checkout } = useCheckoutContext()
  const { count, createCheckout } = useCheckout()
  const { customer } = useCustomerDetails()
  const locales = useLanguage(`cart`)
  const { storeConfig } = useLocation()
  const { customPaymentEnabled, customPaymentUsers, customPaymentDescription } = usePaymentData()

  if (!checkout && storeConfig.siteCurrency) createCheckout(storeConfig.siteCurrency)

  if (count === 0 && checkout?.lines?.length > 0) {
    console.log("[investigate]: clearCart called")
    clearCart()
  }

  const items = excludeGroupedAndWrappingItems(checkout?.lines)

  useEffect(() => {
    if (customPaymentEnabled) {
      setOnedaypayActive(true)
    } else if (customPaymentUsers && Array.isArray(customPaymentUsers)) {
      const pattern = customPaymentUsers.join("|")
      const isExemptEmail = customer?.email?.match(new RegExp(pattern, "gi"))
      setOnedaypayActive(!!isExemptEmail)
    }
  }, [customer, customPaymentEnabled])

  Component.displayName = name
  return (
    <Component
      activeCart={activeCart}
      checkout={checkout}
      count={count}
      customPaymentDescription={customPaymentDescription}
      items={items}
      getGroupedItems={getGroupedItems}
      locales={locales}
      onedaypayActive={onedaypayActive}
      setActiveCart={setActiveCart}
      setShowPopup={setShowPopup}
      showPopup={showPopup}
    />
  )
}
