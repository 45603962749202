import React from "react"

import { Link } from "gatsby"
import { withNavigationMain } from "./withNavigationMain"
import { Drawer } from "../../Modals/Drawer/Drawer"
import { NavigationMainMega } from "./Mega/NavigationMainMega"
import { NavigationMainItem } from "./Item/NavigationMainItem"
import { NavigationMainDropdown } from "./Dropdown/NavigationMainDropdown"
import { Item, Section, Nav, List, MegaList, DropdownListWrapper, DropdownList, StyledIcon, Close, StyledButton } from "./NavigationMainStyles"

const closeItem = React.createRef()

interface Props {
  activeImage: boolean
  activeMenu: boolean
  images: Array<any>
  navigation: any
  setActiveImage: any
  setActiveMenu: any
  appearance: any
}

export const NavigationMain = withNavigationMain(
  ({ activeImage, activeMenu, images, navigation, setActiveImage, setActiveMenu, appearance }: Props) => (
    <Section>
      <Nav>
        <List>
          {navigation &&
            navigation.items &&
            navigation.items.map(item => (
              <NavigationMainItem
                key={item.title}
                link={item.link}
                title={item.title}
                items={item.items}
                active={activeMenu}
                setActive={setActiveMenu}
              />
            ))}

          <Close onClick={() => setActiveMenu(false)} ref={closeItem} visible={activeMenu}>
            <StyledIcon />
          </Close>
        </List>
      </Nav>

      <Drawer
        active={
          activeMenu && closeItem?.current?.offsetLeft && closeItem?.current?.offsetWidth
            ? closeItem.current.offsetLeft + closeItem.current.offsetWidth * 1.5
            : activeMenu
        }
        activeImage={activeImage}
        images={images}
        navigation={`true`}
        setActive={setActiveMenu}
      >
        <MegaList active={activeMenu}>
          {navigation &&
            navigation.items &&
            navigation.items
              .find(item => item.items)
              .items.map((item, i) => (
                <NavigationMainMega
                  key={item.title}
                  active={activeMenu}
                  index={i}
                  items={item.items}
                  link={item.link}
                  setActive={setActiveMenu}
                  setActiveImage={setActiveImage}
                  title={item.title}
                />
              ))}
        </MegaList>

        <DropdownListWrapper active={activeMenu}>
          <DropdownList active={activeMenu}>
            {navigation &&
              navigation.items &&
              navigation.items.map((item, i) => (
                <NavigationMainDropdown
                  key={item.title}
                  index={i}
                  link={item.link}
                  title={item.title}
                  items={item.items}
                  active={activeMenu}
                  setActive={setActiveMenu}
                />
              ))}
            {appearance?.url && appearance?.text ? (
              <Item>
                <StyledButton as={Link} to={appearance?.url} target="_blank" btnStyle={appearance?.btnStyle}>
                  {appearance?.text}
                </StyledButton>
              </Item>
            ) : null}
          </DropdownList>
        </DropdownListWrapper>
      </Drawer>
    </Section>
  )
)
