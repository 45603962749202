import React from "react"

import { withNavigationMainDropdown } from "./withNavigationMainDropdown"
import { Item, SubList, StyledLink, StyledSubLink, StyledSubButton, SubButtonText, IconArrowRight } from "../NavigationMainStyles"

interface Props {
  active: boolean
  activeItem: any
  index: number
  items: Array<any>
  link: string
  setActive: any
  setActiveItem: any
  title: string
}

export const NavigationMainDropdown = withNavigationMainDropdown(
  ({ active, activeItem, link, items, index, setActive, setActiveItem, title }: Props) => (
    <Item index={index}>
      <StyledLink active={active ? `active` : undefined} to={link.url} onClick={() => setActive(false)} title={title} index={index}>
        {title}
      </StyledLink>

      {items && items.length ? (
        <SubList active={true}>
          {items.map(item => {
            const open = activeItem === item.title

            return item.items && item.items.length ? (
              <div key={item.title}>
                <StyledSubButton active={active ? `active` : undefined} title={item.title} onClick={() => setActiveItem(open ? false : item.title)}>
                  <IconArrowRight active={open ? `true` : undefined} />
                  <SubButtonText>{item.title.replace(title, ``).trim()}</SubButtonText>
                </StyledSubButton>

                <SubList active={activeItem === item.title}>
                  {item.items.map((link, iteration) => (
                    <StyledSubLink
                      key={iteration}
                      active={active ? `active` : undefined}
                      to={link.link.url}
                      onClick={() => setActive(false)}
                      title={link.title}
                      inset={`true`}
                    >
                      {link.title}
                    </StyledSubLink>
                  ))}
                </SubList>
              </div>
            ) : (
              <StyledSubLink
                key={item.title}
                active={active ? `active` : undefined}
                to={item.link.url}
                onClick={() => setActive(false)}
                title={item.title}
              >
                {title.replace(title, ``).trim()}
              </StyledSubLink>
            )
          })}
        </SubList>
      ) : null}
    </Item>
  )
)
