import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useApp } from "@hooks/useApp"
import { useCore } from "@hooks/useCore"
import { useRoutes } from "@hooks/useRoutes"
import { useLocation } from "@hooks/useLocation"

export const withGeoLocationPopup = Component => ({ name = `GeoLocationPopup` }) => {
  const [isOpen, setIsOpen] = useState(false)
  // const [targetStore, setTargetStore] = useState("")

  const { config } = useApp()
  const {
    config: {
      stores,
      settings: { keys, params },
    },
    geoLocationDismissed,
  } = useApp()
  const {
    helpers: { storage, isBrowser },
  } = useCore()
  const { country, userLocation, locating } = useLocation()
  const { getUrlParameter } = useRoutes()

  // const relocated = getUrlParameter(params.relocated)

  const redirectUrl = useMemo(() => {
    if (!isBrowser) return null
    if (window.location.href.endsWith("au/")) {
      return `${config.app.url}/us/`
    } else if (window.location.href.endsWith("us/")) {
      return `${config.app.url}/au/`
    } else {
      return window.location.href
    }
  }, [])

  const targetStore = useMemo(() => {
    const target = stores[process.env.GATSBY_SHOPIFY_DEFAULT_SHOP]
    return target?.siteLocation
  }, [stores])

  const isAu = useMemo(() => {
    return targetStore === "AU" ? true : false
  }, [targetStore])

  const handleClose = useCallback(() => {
    storage.set(keys?.geoLocation, true, 7)
    setIsOpen(false)
  }, [storage])

  useEffect(() => {
    if (!locating) {
      if (targetStore && userLocation && targetStore !== userLocation) {
        if (!geoLocationDismissed) {
          setIsOpen(true)
        }
      }
    } else {
      setIsOpen(false)
    }
  }, [locating, geoLocationDismissed, targetStore, userLocation])

  Component.displayName = name
  //   return <Component active={isOpen} setActive={handleClose} handleClose={handleClose} isAu={isAu} />
  return <Component active={isOpen} redirectUrl={redirectUrl} handleClose={handleClose} isAu={isAu} />
}
