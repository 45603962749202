import styled from "@emotion/styled"
import tw from "twin.macro"

import Account from "static/icons/theme/account.svg"
import Close from "static/icons/theme/close.svg"
import Menu from "static/icons/theme/menu.svg"
import Search from "static/icons/theme/search.svg"

import { P } from "../../Styled/Text"

export const IconAccount = tw(Account)`block w-2 h-2 mb-0-1`
export const IconClose = styled(Close)`
  ${tw`absolute inset-0 block transform`}
  ${props => (props.active ? tw`rotate-90` : tw`rotate-0`)}
  ${props => (props.active ? tw`opacity-100` : tw`opacity-0`)}
`
export const IconMenu = styled(Menu)`
  ${tw`absolute inset-0 block transform`}
  ${props => (props.active ? tw`rotate-0` : tw`rotate-90`)}
  ${props => (props.active ? tw`opacity-100` : tw`opacity-0`)}
`

export const IconSearch = tw(Search)`block w-2 h-2`
export const Section = tw.nav`w-2/3 md:w-1/3 z-20 md:pr-0-2`

export const List = tw.ul`flex items-center justify-end`
export const Notification = styled(P)`
  ${tw`hidden md:block absolute left-0 top-0 ml-1-2 transform transition-all duration-200 pointer-events-none`}
  ${props => (props.translate ? tw`opacity-0 invisible translate-y-3` : tw`opacity-100 visible translate-y-0`)}
`
export const ListItem = styled.li`
  ${tw`relative block ml-1 md:ml-1-4 transform`}
  ${props => (props.noMargin ? tw`ml-0` : ``)}
  ${props => (props.noMarginLarge ? tw`md:ml-0` : ``)}
  ${props => (!props.noTransition ? tw`transition-all duration-200` : ``)}
  ${props => (props.translate ? tw`md:opacity-0 md:invisible md:-translate-y-3 md:pointer-events-none` : tw`opacity-100 visible translate-y-0`)}
`
export const ListItemHiddenSmall = tw(ListItem)`hidden md:block`
export const ListItemHiddenLarge = tw(ListItem)`block md:hidden`
export const Button = tw.button`flex items-center justify-center focus:outline-none`
export const MobileNav = tw.button`block no-underline md:hidden ml-1 focus:outline-none relative w-2 h-2`
export const StyledButton = styled.button`
  ${tw`sm:hidden flex items-center justify-center uppercase tracking-wider text-center text-xxs font-medium focus:outline-none transition-all duration-200 px-2 py-0-8`}
  ${props =>
    props?.btnStyle
      ? props?.btnStyle === "light"
        ? tw`hover:bg-dark hover:text-light bg-grey-DEFAULT text-dark`
        : tw`bg-dark text-light hover:bg-grey-DEFAULT hover:text-dark`
      : ""}
`
