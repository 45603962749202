import React from "react"

import { withNavigationFooter } from "./withNavigationFooter"
import { Booking } from "../../Booking/Booking"
import { NavigationFooterItem } from "./Item/NavigationFooterItem"
import { NavigationFooterPopup } from "./Popup/NavigationFooterPopup"
import { FooterNav, List } from "./NavigationFooterStyles"

export const NavigationFooter = withNavigationFooter(
  ({ activeMenu, activePopup, booking, navigation, setActiveMenu, setActivePopup, setBooking }) => (
    <>
      <FooterNav>
        <List>
          {navigation?.items?.map((item, i) => (
            <NavigationFooterItem
              key={item.title}
              index={i}
              title={item.title}
              items={item.items}
              active={item.title === activeMenu ? `active` : undefined}
              setActiveMenu={setActiveMenu}
              setActivePopup={setActivePopup}
              setBooking={setBooking}
            />
          ))}
        </List>
      </FooterNav>

      <Booking active={booking ? true : false} booking={booking} setActive={setBooking} prefix={`footer`} />
      <NavigationFooterPopup active={activePopup} setActive={setActivePopup} />
    </>
  )
)
