import gql from "graphql-tag"

import { PRODUCT_IMAGE_FRAGMENT } from "./productImageFragment"

export const PRODUCT_VARIANT_FRAGMENT = gql`
  fragment ProductVariantFragment on ProductVariant {
    id
    availableForSale
    image {
      ...ProductImageFragment
    }
    quantityAvailable
    requiresShipping
    selectedOptions {
      name
      value
    }
    sku
    title
    priceV2 {
      amount
      currencyCode
    }
    compareAtPriceV2 {
      amount
      currencyCode
    }
    selectedOptions {
      name
      value
    }
    weight
    weightUnit
  }
  ${PRODUCT_IMAGE_FRAGMENT}
`
