import { css } from "@emotion/core"
import styled from "@emotion/styled"
import tw from "twin.macro"

import Icon from "static/icons/theme/close.svg"

import { P } from "../../Styled/Text"

export const Outer = styled.div`
  ${tw`fixed inset-0 transition-all duration-200`}
  ${props => (props.active ? tw`visible` : tw`invisible`)}
  ${props => (props.active ? tw`duration-500` : ``)}
  ${props => (props.navigation ? tw`z-10` : tw`z-20`)}
`
export const Inner = styled.div`
  ${tw`absolute h-full w-full max-w-full bg-light text-dark p-1-6 transform transition-all duration-200 ease-cubic`}
  ${props => (props.right ? tw`right-0` : ``)}
  ${props => (props.active ? tw`duration-500 translate-x-0` : props.right ? tw`translate-x-full` : tw`-translate-x-full`)}
  ${props =>
    props.active && typeof props.active === `number`
      ? css`
          @media (min-width: 850px) {
            max-width: ${props.active}px;
          }
        `
      : tw`md:max-w-42`}
`
export const Bg = styled.div`
  ${tw`fixed bg-dark inset-0 cursor-pointer transition-all duration-200`}
  ${props => (props.active ? tw`opacity-70` : tw`opacity-0`)} ${props => (props.active ? tw`duration-500` : ``)}
`
export const StyledIcon = styled(Icon)`
  ${tw`block w-2 h-2`}
`
export const Close = styled.button`
  ${tw`absolute top-0 right-0 cursor-pointer z-30 p-1-6 cursor-pointer focus:outline-none`}
  ${props => (props.navigation ? tw`hidden` : ``)}
`
export const Caption = styled(P)`
  ${tw`text-sm fixed bottom-0 right-0 pb-2-4 pr-2-4 transform transition-all duration-200 duration-500`}
  ${props => (props.colour === `Dark` ? tw`text-dark` : tw`text-light`)}
  ${props => (props.active ? tw`opacity-100 visible translate-y-0` : tw`opacity-0 invisible translate-y-3`)}
`
export const ImageWrapper = styled.div`
  ${tw`hidden md:block absolute h-full w-full bottom-0 transition-all duration-200`}
  ${props =>
    props.offset && typeof props.offset === `number`
      ? css`
          @media (min-width: 850px) {
            left: ${props.offset}px;
          }
        `
      : tw`left-0`}
  ${props => (props.active ? tw`opacity-100 visible` : tw`opacity-0 invisible`)}
`
