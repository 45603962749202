module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://659ae104e1ff42f1b30a33ab5e3c22bc@o812806.ingest.sentry.io/5805602","sampleRate":1},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","name":"Kyha","short_name":"Kyha","description":"Wedding Dresses designed and made in Melbourne.","background_color":"#ffff04","theme_color":"#000000","display":"standalone","icon":"static/images/kyha-favicon.png","icons":[{"src":"static/images/kyha-favicon.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f83bc1e65082664c194947e20a0d00aa"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
