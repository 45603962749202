import React from "react"

import { Wrapper, Toggle, Icon, Title, Content } from "./AnnouncementStyles"
interface Props {
  announcement: Record<string, string>
}

export const Announcement = ({ announcement }: Props) => {
  return announcement?.announcements ? (
    <Wrapper bgColour={announcement?.bgColour ?? "#EEEE"}>
      <Title>{announcement?.announcements}</Title>
    </Wrapper>
  ) : null
}
