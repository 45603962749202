import React, { useEffect } from "react"
import { ApolloClient, ApolloProvider, ApolloConsumer, HttpLink, InMemoryCache } from "@apollo/client"

import { useGlobal } from "@hooks/useGlobal"
import { useLocation } from "@hooks/useLocation"

export const ShopifyProvider = ({ children, config, allStores }) => {
  const { shopifyStore: currentStore } = useLocation()
  const { handleGlobalGroupReady } = useGlobal()

  const shopifyConfig = {
    ...config,
    ...(allStores[currentStore]?.shopName && { ...allStores[currentStore] }),
  }

  useEffect(() => {
    handleGlobalGroupReady("shopify")
  }, [])

  const client = new ApolloClient({
    link: new HttpLink({
      uri: `https://${shopifyConfig?.shopDomain}/api/${shopifyConfig?.apiVersion}/graphql.json`,
      headers: {
        "X-Shopify-Storefront-Access-Token": shopifyConfig?.accessToken,
      },
    }),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export const withShopify = Component => props => <ApolloConsumer>{client => <Component {...props} shopify={client} />}</ApolloConsumer>
