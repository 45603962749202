import { Results, Sensors, useSearch } from "@usereactify/search"
import { CardProduct, SearchFormResultItem } from "./SearchFormResultItem"

interface Props {
  setActiveSearch: any
}
export const SearchFormResult: React.FC<Props> = ({ setActiveSearch }) => {
  const { searchTerm } = useSearch()
  return (
    searchTerm && (
      // <Suggestions>
      // <ResultsRow verticalSpacing>
      <Results
        listStyle={{
          display: "grid",
          // flexWrap: "wrap",
          gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        }}
        pageSize={4}
        renderError={() => <div />}
        renderLoading={() => <div />}
        renderNoResults={() => <div />}
        renderResultCardProduct={renderProps => (
          <SearchFormResultItem
            key={renderProps.product.id}
            // currencyCode=""
            // list="Instant Search"
            list="Search Suggestions"
            // onSale={false}
            // pagePosition={renderProps.pagePosition}
            product={renderProps.product}
            setActiveSearch={setActiveSearch}
          />
        )}
      />
      // </ResultsRow>
      // </Suggestions>
    )
  )
}
