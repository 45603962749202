import { HeadlessCore } from "@dotdev/headless-core-app"

const decodeShopifyId = (id, type) => {
  return id ? id.split(`${type}/`)[1] : id;
};

const encodeShopifyId = (id, type) => {
  return id ? `gid://shopify/${type}/${id}` : id;
};

export const useCore = () => {
  const isDev = process.env.GATSBY_ACTIVE_ENV === "development" || process.env.NODE_ENV === "development"

  return {
    ...HeadlessCore,
    helpers: {
      ...HeadlessCore.helpers,
      decodeShopifyId,
      encodeShopifyId,
      isDev
    },
  }
}