import styled from "@emotion/styled"
import tw from "twin.macro"

export const SearchInput = tw.input`
  relative appearance-none pr-2 focus:outline-none text-sm font-body font-normal text-dark w-full tracking-wide bg-transparent
`
export const Input = styled.input`
  ${tw`relative appearance-none px-1-6 h-4 focus:outline-none text-sm font-body font-normal w-full tracking-wide leading-normal bg-transparent border border-grey-wild transition-all duration-200`}
  ${({ inverted }) => (inverted ? tw`text-light focus:border-light` : tw`text-dark focus:border-dark`)}
  ${({ readOnly }) => readOnly && tw`text-grey-mid`}
  ${({ disabled }) => disabled && tw`pointer-events-none opacity-75`}
  ${({ errors }) => errors && tw`border-error focus:border-error`}
`
