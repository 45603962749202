import React from "react"

import { withCopyright } from "./withCopyright"
import { StyledP } from "./CopyrightStyles"

export const Copyright = withCopyright(({ date, title }) => (
  <StyledP>
    &copy; {title} {date.getFullYear()}
  </StyledP>
))
