import { useStaticQuery, graphql } from "gatsby"

export const useTemplate = () =>
  useStaticQuery(graphql`
    query SANITY_TEMPLATE {
      global: sanityTemplateGlobal {
        title
        description
      }
      collection: sanityCollectionPage {
        hoverImageIndex
      }
    }
  `)
