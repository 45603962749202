import { useStaticQuery, graphql } from "gatsby"

export const useSettings = () =>
  useStaticQuery(graphql`
    query {
      appearance: sanitySettingAppearance {
        themeDisplay
        url_au
        url_us
        text
        btnStyle
        themeColour {
          hex
        }
        themeBgColour {
          hex
        }
      }
      forms: allSanitySettingForms {
        edges {
          node {
            id: _id
            title
            slug {
              current
            }
            subject
            types {
              title
              recipients
              options
            }
          }
        }
      }
      announcement: sanitySettingAnnouncement {
        bgColour
        announcements
      }
      maintenance: sanitySettingMaintenance {
        title
        password
        enabled
      }
      menus: sanitySettingMenus {
        products {
          items: _rawItems(resolveReferences: { maxDepth: 10 })
          slug: _rawSlug(resolveReferences: { maxDepth: 10 })
        }
        header {
          items: _rawItems(resolveReferences: { maxDepth: 10 })
          slug: _rawSlug(resolveReferences: { maxDepth: 10 })
        }
        footer {
          items: _rawItems(resolveReferences: { maxDepth: 10 })
          slug: _rawSlug(resolveReferences: { maxDepth: 10 })
        }
      }
      organisation: sanitySettingOrganisation {
        title
        titleShort
        description
        headline
        accountHeadline
        author
        logo {
          asset {
            url
          }
        }
        addressLocality
        addressRegion
        postalCode
        streetAddress
        separator
        url
      }
      languages: sanitySettingSeo {
        hrefLangs: languages {
          _key
          language
          region
          url
        }
      }
      scripts: sanitySettingScripts {
        header: _rawTrackingHeader(resolveReferences: { maxDepth: 10 })
        body: _rawTrackingBody(resolveReferences: { maxDepth: 10 })
        footer: _rawTrackingFooter(resolveReferences: { maxDepth: 10 })
      }
      social: sanitySettingSocial {
        instagramAccessToken
        instagramClientID
        instagramUsername
        channels {
          title
          url
        }
      }
      tracking: sanitySettingTracking {
        googleTagManagerId
        googleSiteVerify
        googleAnalyticsId
        facebookPixelId
        facebookAppId
      }
    }
  `)
