import React, { useEffect, useLayoutEffect, useRef, useState } from "react"

import { useApp } from "@hooks/useApp"
import { useCore } from "@hooks/useCore"
import { useSettings } from "@hooks/useSettings"
import { useScrollHidden, useScrollPosition } from "@hooks/useScroll"

export const withHeader = Component => ({ name = `Header`, location, title = null }) => {
  const [offsetHeight, setOffsetHeight] = useState(0)
  const [activeScrolled, setActiveScrolled] = useState(false)
  const [activeSticky, setSticky] = useState(true)
  const [activeMenu, setActiveMenu] = useState(false)
  const [activeSearch, setActiveSearch] = useState(false)
  const {
    invertedTheme,
    setInvertedTheme,
    transparentTheme,
    setTransparentTheme,
    activeCart,
    setActiveCart,
    terms,
    setTerms,
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()
  const { organisation, announcement } = useSettings()
  const header = useRef()
  const search = useRef()
  const offsetThreshold = 25

  const handleSearch = (value, keepStorage = "") => {
    if (value && location?.pathname && location.pathname !== "/") {
      storage.set(keys.searchOrigin, location.pathname)
    } else if (!value && location?.pathname === storage.get(keys.searchOrigin) && keepStorage !== "keepStorage") {
      storage.remove(keys.searchOrigin)
    }
    setActiveSearch(value)
  }

  useLayoutEffect(() => {
    const handleRedraw = () => {
      const offset = header.current.offsetHeight
      if (offset !== offsetHeight) {
        setOffsetHeight(offset)
      }
    }

    window.addEventListener("resize", handleRedraw)
    handleRedraw()
    return () => window.removeEventListener("resize", handleRedraw)
  })

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const shown = currPos.y > -offsetThreshold ? true : currPos.y > prevPos.y
      const scrolled = currPos.y <= -offsetThreshold
      if (scrolled !== activeScrolled) setActiveScrolled(scrolled)
      if (shown !== activeSticky) setSticky(shown)
    },
    [activeSticky, offsetHeight]
  )

  useEffect(() => {
    if (search?.current) {
      setTimeout(() => {
        if (activeSearch) {
          search.current?.focus()
        }
      }, 300)
    }

    if (!activeSticky && activeSearch) {
      setActiveSearch(false)
    }

    useScrollHidden(activeCart || activeMenu)
  }, [activeCart, activeMenu, activeSearch, activeSticky])

  useEffect(() => {
    const searchInput = document.querySelector('input[aria-label="q"]')
    if (activeSearch && searchInput) {
      searchInput.select()
    }
    if (activeSearch && terms !== ``) {
      setTerms(``)
    }
  }, [activeSearch])

  useEffect(() => {
    if (invertedTheme) {
      setInvertedTheme(false)
    }

    if (transparentTheme) {
      setTransparentTheme(false)
    }

    const searchOrigin = storage.get(keys.searchOrigin)
    setActiveSearch(searchOrigin && searchOrigin === location?.pathname)
  }, [location])

  Component.displayName = name
  return (
    <Component
      activeCart={activeCart}
      activeMenu={activeMenu}
      activeSticky={activeSticky}
      activeScrolled={activeScrolled}
      activeSearch={activeSearch}
      handleSearch={handleSearch}
      header={header}
      invertedTheme={invertedTheme}
      offsetHeight={offsetHeight}
      location={location}
      routes={routes}
      search={search}
      setActiveCart={setActiveCart}
      setActiveMenu={setActiveMenu}
      organisation={organisation}
      title={title}
      transparentTheme={transparentTheme}
      announcement={announcement}
    />
  )
}
