import React from "react"
import BlockContent from "@sanity/block-content-to-react"

import { withRichTextContent } from "./withRichTextContent"

interface Props {
  content: Array<any>
  rte?: boolean
  serializers: any
}

export const RichTextContent = withRichTextContent(({ content, rte = true, serializers }: Props) => (
  <BlockContent blocks={content} className={rte && `rte`} serializers={serializers} />
))
