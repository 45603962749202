import React from "react"

import { useLanguage } from "@hooks/useLanguage"

export const withDrawer = Component => ({ name = `Drawer`, active, activeImage, children, images, navigation, right, setActive }) => {
  const locales = useLanguage(`accessibility`)

  Component.displayName = name
  return (
    <Component
      active={active}
      activeImage={activeImage}
      images={images}
      locales={locales}
      navigation={navigation}
      right={right}
      setActive={setActive}
    >
      {children}
    </Component>
  )
}
