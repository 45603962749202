import { useState, useCallback } from "react"

import { useAnalytics } from "./useAnalytics"
import { useApp } from "./useApp"
import { useCheckoutContext } from "./useCheckout"
import { useCore } from "./useCore"
import { useQueries } from "./useQueries"
import { useShopify } from "./useShopify"
import { CHECKOUT_LINE_ITEMS_REMOVE } from "@app/graphql/mutations"

export const useCart = () => {
  const {
    config: {
      settings: { keys, products },
    },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()
  const {
    mutations: { CHECKOUT_LINE_ITEMS_REPLACE, CHECKOUT_LINE_ITEMS_ADD },
  } = useQueries()
  const { checkout, setCheckout } = useCheckoutContext()
  const { useMutation, checkoutNormaliser, cartNormaliser } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const checkoutId = checkout?.id || storage.get(keys?.checkout)

  const [lineItemsReplace] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE)
  const [lineItemAdd] = useMutation(CHECKOUT_LINE_ITEMS_ADD)

  const [lineItemRemove] = useMutation(CHECKOUT_LINE_ITEMS_REMOVE)

  const addToCart = useCallback(
    async (merchandiseId, quantity = 1, attributes = [], haveCustomisedTag) => {
      setLoading(true)
      let alreadyInCart = false
      const lineItems =
        checkout?.lineItems?.map(lineItem => {
          if (lineItem?.variant?.id === merchandiseId && haveCustomisedTag === false) {
            alreadyInCart = true
            return {
              attributes: [
                ...lineItem?.attributes?.map(({ key, value }) => ({
                  key,
                  value,
                })),
                ...(attributes || []),
              ],
              quantity: lineItem?.quantity + quantity,
              merchandiseId: merchandiseId,
            }
          }
          return {
            attributes: lineItem?.attributes?.map(({ key, value }) => ({
              key,
              value,
            })),
            quantity: lineItem?.quantity,
            merchandiseId: lineItem?.variant?.id,
          }
        }) || []

      try {
        const preparedLines = [...(alreadyInCart ? lineItems : [...lineItems, { quantity, merchandiseId: merchandiseId, attributes }])]
        const {
          data: { cartLinesAdd: data, userErrors: errors },
        } = await lineItemAdd({
          variables: {
            cartId: checkoutId,
            lines: preparedLines,
          },
        })

        if (errors?.length) {
          setErrors(errors)
        }
        if (data) {
          await setCheckout(await cartNormaliser(data?.cart))
        }
        setLoading(false)

        trackCartUpdate("add", merchandiseId, quantity, cartNormaliser(data?.cart)?.lines)
      } catch (e) {
        console.error(e)
      }
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, trackCartUpdate]
  )

  const addMultipleToCart = useCallback(
    async (input: Array<{ variantId: number; quantity: number; customAttributes: Array<{ [key: string]: string }> }>) => {
      setLoading(true)
      let lineItems = checkout?.lineItems || []

      for (const item of input) {
        let alreadyInCart = false
        const convertedLineItems =
          lineItems.map(lineItem => {
            const isGroupedItem = lineItem?.customAttributes?.find(attribute => attribute.key === "_grouped")

            if (lineItem?.variant?.id === item.variantId && !isGroupedItem) {
              alreadyInCart = true
              return {
                customAttributes: [
                  ...lineItem?.customAttributes?.map(({ key, value }) => ({
                    key,
                    value,
                  })),
                  ...(item.customAttributes || []),
                ],
                quantity: lineItem?.quantity + item.quantity || 1,
                variantId: item.variantId,
              }
            }
            return {
              customAttributes: lineItem?.customAttributes?.map(({ key, value }) => ({
                key,
                value,
              })),
              quantity: lineItem?.quantity,
              variantId: lineItem?.variant?.id || lineItem?.variantId,
            }
          }) || []

        lineItems = alreadyInCart
          ? convertedLineItems
          : [...convertedLineItems, { quantity: item.quantity || 1, variantId: item.variantId, customAttributes: item.customAttributes || [] }]
      }

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        await setCheckout(checkoutNormaliser(data?.checkout))
      }
      setLoading(false)

      for (const item of input) {
        trackCartUpdate("add", item.variantId, item.quantity, checkoutNormaliser(data?.checkout)?.lineItems)
      }
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, trackCartUpdate]
  )

  const removeFromCart = useCallback(
    async variants => {
      setLoading(true)
      const quantity = checkout?.lines.filter(line => line.id === variants[0].variantId).map(({ quantity }) => quantity)[0] || 1
      trackCartUpdate("remove", variants[0].variantId, quantity, checkout?.lines)

      const lineItems = checkout?.lines
        .filter(lineItem => lineItem.merchandise.id !== variants[0].variantId)
        .map(lineItem => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
          }),
          quantity: lineItem.quantity,
          id: lineItem.id,
        }))

      const productToRemove = checkout?.lines.filter(lineItem => lineItem.merchandise.id === variants[0].variantId).map(lineItem => lineItem.id)
      if (productToRemove) {
        try {
          const {
            data: { cartLinesRemove: data, userErrors: errors },
          } = await lineItemRemove({
            variables: {
              cartId: checkoutId,
              lineIds: productToRemove,
            },
          })

          if (errors?.length) setErrors(errors)
          if (data) await setCheckout(checkoutNormaliser(data?.cart))
        } catch (e) {
          console.error(e)
        }
      }
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, trackCartUpdate]
  )

  const removeMultipleFromCart = useCallback(
    async (input: Array<{ variantId: string; qty?: number; groupedId?: string }>) => {
      setLoading(true)
      // Normalize lineItems
      let lines: Array<{ variantId: string; quantity: number; customAttributes?: Array<{ key: string; value: string }> }> =
        checkout?.lines?.map(lineItem => lineItem.id) || []

      // Replace quantity
      for (const item of input) {
        trackCartUpdate("remove", item, 1, checkout?.lines)
        lines = lines.map(lineItem => {
          if (
            (lineItem === item || lineItem.variantId === item) &&
            (!item.groupedId ||
              (item.groupedId && lineItem.customAttributes?.find(attribute => attribute.key === "_grouped")?.value === item.groupedId))
          ) {
            return {
              item,
            }
          }

          return lineItem
        })
      }
      if (lines && lines?.length > 0) {
        try {
          const {
            data: { cartLinesRemove: data, userErrors: errors },
          } = await lineItemRemove({
            variables: {
              cartId: checkoutId,
              lineIds: lines,
            },
          })

          if (errors?.length) setErrors(errors)
          if (data) await setCheckout(checkoutNormaliser(data?.checkout))
        } catch (e) {
          console.error(e)
        }
      }
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, trackCartUpdate]
  )

  const updateQuantity = useCallback(
    async (variantId, quantity, action = "add") => {
      setLoading(true)
      const lines = checkout?.lines.map(lineItem => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
        }),
        quantity: lineItem.merchandise.id === variantId ? quantity : lineItem.quantity,
        id: lineItem.id,
      }))
      // add a mutate quanity call here. - future blake
      const {
        data: { cartLinesUpdate: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          cartId: checkoutId,
          lines,
        },
      })

      if (errors?.length) setErrors(errors)

      if (data) await setCheckout(checkoutNormaliser(data?.cart))

      setLoading(false)

      trackCartUpdate(action, variantId, quantity, checkoutNormaliser(data?.checkout)?.lines)
    },
    [trackCartUpdate, lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId]
  )

  const updateVariant = useCallback(
    async (prevVariantId, variantId) => {
      setLoading(true)
      const lineItems = checkout?.lineItems.map(lineItem => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
        }),
        quantity: lineItem.quantity,
        variantId: lineItem.variant.id === prevVariantId ? variantId : lineItem.variant.id,
      }))
      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout]
  )

  const updateItem = useCallback(
    async (variantId, quantity, customAttributes) => {
      setLoading(true)
      const lineItems = checkout?.lineItems?.map(lineItem =>
        lineItem.variant.id === variantId
          ? {
              customAttributes: [
                ...new Map(
                  [
                    ...lineItem?.customAttributes?.map(({ key, value }) => ({
                      key,
                      value,
                    })),
                    ...Object.entries(customAttributes)?.map(attr => ({
                      key: attr[0],
                      value: attr[1],
                    })),
                  ].map(item => [item?.key, item])
                ).values(),
              ],
              variantId,
              quantity,
            }
          : {
              ...(lineItem?.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }) => ({
                  key,
                  value,
                })),
              }),
              quantity: lineItem.quantity,
              variantId: lineItem.variant.id,
            }
      )

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(checkoutNormaliser(data?.checkout))
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId]
  )

  const clearCart = useCallback(async () => {
    setLoading(true)

    checkout?.lines?.map(({ variant, quantity }) => trackCartUpdate("remove", variant?.id, quantity, checkout?.lines))

    if (checkout && checkout?.lines?.length > 0) {
      const {
        data: { cartLinesRemove: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          cartId: checkoutId,
          lines: checkout?.lines,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(checkoutNormaliser(data?.checkout))
    }
    setLoading(false)
  }, [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, trackCartUpdate])

  const getGroupedItems = (lineItem, lineItems) =>
    lineItems.filter(
      item => item.customAttributes && item.customAttributes.find(({ key, value }) => key === `_grouped` && value === lineItem.variant.id)
    )
  const excludeGroupedAndWrappingItems = lineItems => {
    if (lineItems?.edges) {
      return []
    }

    return lineItems
      ?.filter(lineItem => lineItem?.merchandise?.product?.handle !== products.giftWrappingHandle)
      ?.filter(lineItem => !(lineItem.attributes && lineItem.attributes.find(({ key }) => key === `_grouped`)))
  }

  return {
    getGroupedItems,
    excludeGroupedAndWrappingItems,
    addToCart,
    addMultipleToCart,
    removeFromCart,
    removeMultipleFromCart,
    updateQuantity,
    updateVariant,
    updateItem,
    clearCart,
    loading,
    errors,
  }
}
