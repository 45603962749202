import React from "react"

import { RichText } from "../../../RichText/RichText"
import { Popup } from "../../../Modals/Popup/Popup"

import { StyledTermsContainer } from "../CustomerPaymentsStyles"

interface Props {
  active: boolean
  content: any
  setActive: any
}

export const CustomerPaymentsInformationPopup = ({ active, setActive, content }: Props) => (
  <Popup active={active} setActive={setActive}>
    <StyledTermsContainer>
      <RichText content={content} />
    </StyledTermsContainer>
  </Popup>
)
