import gql from "graphql-tag"

import { CUSTOMER_FRAGMENT } from "../fragments/customerFragment"
import { ORDER_FRAGMENT } from "../fragments/orderFragment"
import { MAILING_ADDRESS_FRAGMENT } from "../fragments/mailingAddressFragment"

export const GET_CUSTOMER = gql`
  query GET_CUSTOMER($countryCode: CountryCode!, $customerAccessToken: String!) @inContext(country: $countryCode) {
    customer(customerAccessToken: $customerAccessToken) {
      ...CustomerFragment
      addresses(first: 10) {
        edges {
          node {
            ...MailingAddressFragment
          }
        }
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
  ${MAILING_ADDRESS_FRAGMENT}
`

export const GET_CUSTOMER_ORDERS = gql`
  query GET_CUSTOMER_ORDERS($countryCode: CountryCode!, $customerAccessToken: String!, $first: Int!, $reverse: Boolean = false)
  @inContext(country: $countryCode) {
    customer(customerAccessToken: $customerAccessToken) {
      orders(first: $first, reverse: $reverse) {
        edges {
          cursor
          node {
            ...OrderFragment
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
  ${ORDER_FRAGMENT}
`

export const CUSTOMER_MULTIPASS_URL_QUERY = gql`
  query CUSTOMER_MULTIPASS_URL_QUERY($shopName: String!, $idToken: String!, $returnUrl: String!) {
    multipassUrl(shopName: $shopName, idToken: $idToken, returnUrl: $returnUrl) {
      url
    }
  }
`
