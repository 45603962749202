import React from "react"

import { withCartFormCheckout } from "./withCartFormCheckout"

import { PrimaryButton } from "../../Styled/Button"

interface Props {
  disabled: boolean
  handleCheckout: Function
  locales: any
  loading: boolean
  text: string
}

export const CartFormCheckout = withCartFormCheckout(({ disabled, handleCheckout, locales, loading, text }: Props) => (
  <PrimaryButton disabled={disabled || loading} full={`true`} onClick={handleCheckout}>
    {text ? text : locales.checkoutButton}
  </PrimaryButton>
))
