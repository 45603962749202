import React, { memo } from "react"

import { RichTextContent } from "./Content/RichTextContent"

interface Props {
  content: any
  html: any
}

export const RichText = memo(({ content, html, ...props }: Props) =>
  content ? (
    React.cloneElement(<RichTextContent />, {
      ...props,
      content,
    })
  ) : (
    <span className={`rte`} dangerouslySetInnerHTML={{ __html: html }} />
  )
)
