import React from "react"

import { useShopify } from "@hooks/useShopify"

export const withCartItemPrice = Component => ({ name = `CartItemPrice`, money, withCurrency = false }) => {
  const { formatMoney } = useShopify()

  const { amount, currencyCode } = money || {}

  const price = formatMoney(amount, withCurrency ? currencyCode : false)

  Component.displayName = name
  return <Component price={price} />
}
