import { useCallback, useContext, useState } from "react"

import { TrackingContext } from "@providers/tracking"

import { useCheckoutContext } from "./useCheckout"
import { useCore } from "./useCore"
import { useShopify } from "./useShopify"

export const useAnalytics = () => {
  const { setTracked, gtm, VisibilitySensor } = useContext(TrackingContext)
  const [trackedPage, setTrackedPage] = useState(null)
  const [trackedProduct, setTrackedProduct] = useState(null)
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { checkout } = useCheckoutContext()
  const { productNormaliser } = useShopify()

  const getVariantOptionValue = (options, selectedName) => options?.find(({ name }) => name?.toLowerCase() === selectedName?.toLowerCase())?.value

  const trackPageView = useCallback(() => {
    setTimeout(() => {
      if (trackedPage !== document.location.pathname) {
        setTrackedPage(document.location.pathname)
        console.log(`[TRACKING] pageView (${document.title}) fired!`, document.location.pathname)
        gtm.dataLayer({
          dataLayer: {
            event: "Pageview",
            pagePath: document.location.pathname,
            pageTitle: document.title,
          },
        })
      }
    }, 200)
  }, [gtm, trackedPage])

  const trackProductImpression = useCallback(
    async (product, position, list = null) => {
      const { collections, id, title, vendor, product_type } = productNormaliser(product)
      if (title) {
        console.log(`[TRACKING] productImpression (${title} ${list}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: "productImpression",
            ecommerce: {
              currencyCode: checkout?.currencyCode,
              impressions: [
                {
                  id: decodeShopifyId(id?.toString(), "Product"),
                  name: title,
                  brand: vendor,
                  category: collections?.[0]?.title || product_type,
                  list: list || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                  position: position?.toString(),
                },
              ],
            },
          },
        })
      }
    },
    [gtm, checkout, productNormaliser, decodeShopifyId]
  )

  const trackProductClick = useCallback(
    async (product, variant, position, list = null) => {
      const { collections, id, title, vendor, product_type } = productNormaliser(product)
      if (title) {
        console.log(`[TRACKING] productClick (${title} – ${variant?.title}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: "productClick",
            ecommerce: {
              currencyCode: checkout?.currencyCode,
              click: {
                actionField: { list: list || "Collection Results" }, // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                products: [
                  {
                    id: decodeShopifyId(id?.toString(), "Product"),
                    name: title,
                    brand: vendor,
                    category: collections?.[0]?.title || product_type,
                    position: position?.toString(),
                    variant: getVariantOptionValue(variant?.selectedOptions, `Colour`),
                    // dimension2: getVariantOptionValue(variant?.selectedOptions, `Size`),
                    // dimension3: variant?.availableForSale ? `In Stock` : `Out of Stock`,
                  },
                ],
              },
            },
          },
        })
      }
    },
    [gtm, checkout, productNormaliser, decodeShopifyId]
  )

  const trackProductView = useCallback(
    async (product, variant, list = null) => {
      const { collections, id, productType, title, vendor } = productNormaliser(product)
      const productTitle = `${title} – ${variant?.title}`
      if (title && productTitle !== trackedProduct) {
        setTrackedProduct(productTitle)
        console.log(`[TRACKING] productView (${productTitle}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: "productDetail",
            ecommerce: {
              currencyCode: checkout?.currencyCode,
              detail: {
                actionField: { list: list || "Product Page" }, // Product Page, Quick View
                products: [
                  {
                    id: decodeShopifyId(id?.toString(), "Product"),
                    name: title,
                    brand: vendor,
                    category: collections?.[0]?.title || productType,
                    variant: getVariantOptionValue(variant?.selectedOptions, `Colour`),
                    // dimension2: getVariantOptionValue(variant?.selectedOptions, `Size`),
                    // dimension3: variant?.availableForSale ? `In Stock` : `Out of Stock`,
                  },
                ],
              },
            },
          },
        })
      }
    },
    [gtm, checkout, productNormaliser, decodeShopifyId]
  )

  const trackCartUpdate = useCallback(
    async (type, variantId, quantity, lineitems) => {
      const selectedLineItem = lineitems?.filter(({ variant }) => variant?.id === variantId)[0]
      if (selectedLineItem?.title) {
        console.log(`[TRACKING] cartUpdate (${type} – ${selectedLineItem?.title}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: type === "add" ? "addToCart" : "removeFromCart",
            ecommerce: {
              currencyCode: checkout?.currencyCode,
              [type]: {
                products: [
                  {
                    id: decodeShopifyId(variantId?.toString(), "ProductVariant"),
                    name: selectedLineItem?.title,
                    brand: selectedLineItem?.variant?.product?.vendor,
                    category: selectedLineItem?.variant?.product?.productType,
                    quantity,
                    variant: getVariantOptionValue(selectedLineItem?.variant?.selectedOptions, `Colour`),
                    // dimension2: getVariantOptionValue(selectedLineItem?.variant?.selectedOptions, `Size`),
                    // dimension3: selectedLineItem?.variant?.availableForSale ? `In Stock` : `Out of Stock`,
                  },
                ],
              },
            },
          },
        })
      }
    },
    [gtm, checkout, decodeShopifyId]
  )

  const trackPromoImpression = useCallback(
    async ({ analyticsId: id, name, creative, position }) => {
      if (name) {
        console.log(`[TRACKING] promoImpression (${name}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: "promotionView",
            ecommerce: {
              promoView: {
                promotions: [{ id, name, creative, position }],
              },
            },
          },
        })
      }
    },
    [gtm]
  )

  const trackPromoClick = useCallback(
    async ({ analyticsId: id, name, creative, position }) => {
      if (name) {
        console.log(`[TRACKING] promoClick (${name}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: "promotionClick",
            ecommerce: {
              promoClick: {
                promotions: [{ id, name, creative, position }],
              },
            },
          },
        })
      }
    },
    [gtm]
  )

  const trackDynamicAds = ({ eventName, objectProperties }) => {
    if (typeof fbq === `function`) {
      window.fbq("track", eventName, objectProperties)
    }
  }

  const trackEvent = useCallback(async () => {
    if (checkout?.currencyCode) {
      await setTracked(true)
      await trackPageView()
    }
  }, [checkout, setTracked, trackPageView])

  return {
    trackEvent,
    trackPageView,
    trackProductImpression,
    trackProductView,
    trackProductClick,
    trackCartUpdate,
    trackPromoImpression,
    trackPromoClick,
    trackDynamicAds,
    VisibilitySensor,
  }
}
