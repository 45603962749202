import gql from "graphql-tag"
import { VARIANT_WITH_PRODUCT_FRAGMENT } from "./variantWithProductFragment"

// Note that this actually is the Cart API definition, but the name of the fragment is still called CheckoutFragment
// to minimise code change. - Blake
export const CHECKOUT_FRAGMENT = gql`
  fragment CheckoutFragment on Cart {
    id
    createdAt
    updatedAt
    note
    buyerIdentity {
      countryCode
    }
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
    }
    checkoutUrl
    lines(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          merchandise {
            ...VariantWithProductFragment
          }
          quantity
          attributes {
            key
            value
          }
          discountAllocations {
            discountedAmount {
              amount
              currencyCode
            }
          }
        }
      }
    }
    attributes {
      key
      value
    }
  }
  ${VARIANT_WITH_PRODUCT_FRAGMENT}
`
