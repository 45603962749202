import styled from "@emotion/styled"
import tw from "twin.macro"

import { Container } from "../Styled/Container"
import { H2, Label, P } from "../Styled/Text"
import { PrimaryButton, TextButton } from "../Styled/Button"
import { Input } from "../Styled/Input"

import ArrowRight from "static/icons/theme/arrow-right.svg"
import Next from "static/icons/theme/next.svg"

export const Title = tw(Label)`mb-2-4`
export const Form = tw.form`relative flex items-center`
export const Button = tw.button`absolute right-0 px-1 top-0 bottom-0 hover:opacity-70 focus:outline-none`
export const StyledInput = tw(Input)`pr-4 mt-0-2 border-grey-DEFAULT`
export const IconArrowRight = tw(ArrowRight)`block w-1 h-1`

export const StyledContainer = tw(Container)`max-w-lg mb-5`
export const Row = tw.div`flex flex-wrap justify-between -mx-1-2`
export const Column = styled.div`
  ${tw`w-full md:w-1/2 px-1-2`} ${(props: { content: string }) => (props.content ? tw`md:max-w-42` : ``)}
`
export const StyledH2 = tw(H2)`text-center font-sans font-normal leading-relaxed uppercase text-lg  md:text-lg`
export const SeoDescription = tw(P)`text-center font-normal leading-relaxed p-1-6 max-w-md m-auto`
export const ContentLinks = tw.div`-mt-1 w-full md:max-w-29-3`
export const ContentItem = tw.div`mb-3-2 tracking-relaxed`
export const ImageContainer = tw.div`relative mt-4 md:mt-0 w-full h-full`
export const ImageRatio = tw.div`pt-full md:hidden`
export const ImageWrapper = tw.div`absolute inset-0`
export const StyledPrimaryButton = tw(PrimaryButton)`inline-block  md:-mt-1-6`
export const StyledLink = tw(TextButton)`inline-block px-1-2 mt-1-6`

export const Wrapper = tw.div`border-t border-grey-DEFAULT pt-4 md:pt-4-8`
export const LocationsContainer = tw(Container)`mb-12`
export const LocationsRow = styled.div`
  ${tw`flex-col md:flex-row flex -m-0-6`}
  ${(props: { spacing?: boolean; end?: boolean; bottom?: boolean }) =>
    props.spacing ? tw`mb-6-4 md:mb-4` : props.end ? tw`md:justify-end` : props.bottom ? tw`items-end` : ``}
`
export const LocationsColumn = styled.div`
  ${tw`w-full md:w-2/3 p-0-6`}
  ${(props: { end: boolean }) => (props.end ? tw`md:w-1/4` : ``)}
`
export const StyledP = tw(P)`w-full tracking-normal text-grey-darker pb-1`
export const StyledFlex = tw(P)`w-full tracking-normal text-grey-darker flex flex-col`
export const StyledFlagship = tw(Label)`pr-0-3 mb-0-8`
export const StyledLabel = tw(Label)`mb-0-8`
export const DropdownWrapper = tw.div`mt-0-4`
export const Empty = tw(P)`text-center p-0-6 w-full`
export const Stores = tw(Row)`-mt-4-8 md:-mt-0-6 mb-4 justify-start`
export const Store = tw.li`block w-full md:w-1/4 p-0-6`
export const LocationContent = styled.div`
  ${tw`flex flex-col justify-between p-1-6 pb-0-9 border text-left h-full`}
  ${props => (props.highlight ? tw`border-success` : tw`border-grey-DEFAULT`)}
`
export const Filter = styled.div`
  ${props => (props.hiddenLg ? tw`block md:hidden` : tw`mb-1-2 md:mb-0`)}
`
export const Details = tw.div`pb-1-2`
export const Links = tw.div`flex items-end`
export const InfoWrapper = tw.div`block`
export const IconNext = tw(Next)`block w-1-4 h-1-4 ml-0-8`
export const StyledTextButton = tw(TextButton)`flex items-center justify-start mr-2-4 hover:no-underline hover:text-grey-darkest`
export const Checkboxes = tw.div`flex items-center justify-start md:justify-end mt-4 md:mt-0`
export const CheckboxWrapper = tw.div`mr-2-4 md:mr-0 md:ml-3-2`

export const StoresMapContainer = tw.div`md:h-50 h-1/2-vh w-full md:w-auto md:flex-1 max-h-3/4-vh md:max-h-none`
