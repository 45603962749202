import { memo } from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import Icon from "static/icons/theme/close.svg"

import { Link } from "gatsby"

import { H4 } from "../Styled/Text"
import { TextButton } from "../Styled/Button"

export const StyledIcon = styled(Icon)`
  ${tw`block w-2 h-2`}
`
export const Close = styled.button`
  ${tw`absolute top-0 right-0 cursor-pointer z-30 p-1-6 cursor-pointer focus:outline-none`}
  ${props => (props.navigation ? tw`hidden` : ``)}
`

export const Title = tw(H4)`mt-1-6 mb-2-4`

export const Button = styled.a`
  ${tw`bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mt-1-6 mb-1-6`}
`

export const PopupContainer = memo(
  tw.div`w-full max-w-57-7 flex flex-col w-full items-center justify-center text-center bg-white w-1/2 md:pb-3-2 mx-auto pt-2-4 md:pb-3-2 relative`
)

export const Text = memo(tw.div`font-body mb-4 w-2/5`)

export const RedirectButtonContainer = memo(tw.div`w-full flex items-center justify-center max-w-md`)

export const RedirectButton = styled(TextButton)`
  ${tw`font-body leading-normal cursor-pointer text-sm bg-grey-DEFAULT py-1-6 px-1-4 mx-0-4 w-2/5`}
  ${props => (props.active ? tw`underline` : tw`hover:underline`)}
`
export const RedirectLink = styled(Link)`
  ${tw`font-body leading-normal cursor-pointer text-sm  bg-grey-DEFAULT py-1-6 px-1-4 mx-0-4 w-2/5`}
  ${props => (props.active ? tw`underline` : tw`hover:underline`)}
`
