import React from "react"

import { withWishlistCount } from "./withWishlistCount"
import { HiddenSpan } from "../../Styled/Accessibility"
import { SavedCounter, IconSaved, AccountItems } from "../WishlistStyles"

export const WishlistCount = withWishlistCount(({ count, customer, locales, routes }) => (
  <SavedCounter to={customer?.email ? routes.ACCOUNT_SAVED : routes.SAVED}>
    <IconSaved />
    <HiddenSpan>{locales?.title}</HiddenSpan> <AccountItems>{count ? count : " "}</AccountItems>
  </SavedCounter>
))
