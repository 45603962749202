import React from "react"

import { withDrawer } from "./withDrawer"
import { Image } from "../../Image/Image"
import { Outer, Inner, Bg, StyledIcon, Close, ImageWrapper, Caption } from "./DrawerStyles"

interface Props {
  active: any
  activeImage: string
  children: any
  images: Array<any>
  locales: any
  navigation: any
  right: boolean
  setActive: any
}

export const Drawer = withDrawer(({ active, activeImage, children, images, locales, navigation, right, setActive }: Props) => (
  <Outer active={active} setActive={setActive} navigation={navigation}>
    <Bg active={active} onClick={() => setActive(false)} />

    <Inner active={active} navigation={navigation} right={right}>
      <Close navigation={navigation} onClick={() => setActive(false)} title={locales.close}>
        <StyledIcon />
      </Close>

      {children}
    </Inner>

    {images
      ?.filter(image => image?.asset?.url)
      ?.map(image => (
        <ImageWrapper key={image.key} active={active && activeImage === image.key} offset={active}>
          <Image cover src={image.asset.url} />
          <Caption active={active && activeImage === image.key} colour={image.colour}>
            {image.caption}
          </Caption>
        </ImageWrapper>
      ))}
  </Outer>
))
