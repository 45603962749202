import React from "react"

import { withWishlistButton } from "./withWishlistButton"
import { Button, IconActive, IconInactive } from "../WishlistStyles"

interface Props {
  loading: boolean
  inWishlist: boolean
  handleToggle: any
}

export const WishlistButton = withWishlistButton(({ loading, inWishlist, handleToggle }: Props) => (
  <Button disabled={loading} onClick={handleToggle} type={`button`}>
    {inWishlist ? <IconActive /> : <IconInactive />}
  </Button>
))
