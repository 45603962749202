import styled from "@emotion/styled"
import tw from "twin.macro"

import IconArrowDown from "static/icons/theme/arrow-down.svg"

import { PrimaryButton, TextButton } from "../../Styled/Button"
import { Input } from "../../Styled/Input"
import { H4, P, Small } from "../../Styled/Text"

export const StyledPrimaryButton = tw(PrimaryButton)`mb-1-2`

export const Content = styled.form`
  ${tw`w-full max-w-37-7 pb-1-2 px-1-6 mt-2-6`}
  ${props => (props.as === `div` ? tw`max-w-42` : ``)}
`
export const CheckboxWrapper = tw.div`mt-1-6`
export const StyledH4 = tw(H4)`font-sans font-light uppercase tracking-tight text-center text-md mb-3-8`
export const StyledMessage = tw(P)`-mt-2 text-center text-base`
export const StyledSmall = tw(Small)`block ml-1-8 mb-2-4 text-grey-darker`
export const StyledInput = tw(Input)`mb-1`
export const StyledAttributeInput = tw(Input)`border border-grey-DEFAULT`

export const Icon = styled(IconArrowDown)`
  ${tw`block w-0-8 h-0-8 ml-1-6 transform transition-all duration-200`} ${props => (props.active ? tw`rotate-180` : tw`rotate-0`)}
`
export const AddOns = styled.div`
  ${tw`overflow-hidden w-full`} ${props => (props.active ? tw`max-h-1/2-vh` : tw`max-h-0`)}
`
export const Trigger = tw.button`flex items-center justify-start my-1-2 focus:outline-none`
export const Label = tw(P)`text-grey-darker group-hover:text-dark transition-all duration-200`
export const AddOn = styled.div`
  ${props => (props.last ? tw`mb-1-1` : ``)}
`

export const Attributes = tw.div`mt-1-6`
export const Attribute = tw.div`mb-1-6`
export const StyledLabel = tw(P)`mt-1-6 mb-0-3 text-grey-darker`

export const StyledP = tw(P)`tracking-wide mb-3-2`
export const CompareAtPrice = tw.span`ml-1-2 line-through opacity-50`

export const FormContent = tw.div`w-full flex items-start justify-between md:pb-4 md:sticky md:min-h-full-vh top-0 md:w-1/2 lg:w-50 md:mx-2-4 lg:ml-13-8 lg:mr-0 md:pt-8-8`
export const Description = tw.div`w-full md:w-5/6 md:w-3/4 px-1-2 md:px-0`

export const WishlistDesktop = tw.div`text-grey-mid flex items-start justify-end hidden md:flex w-1/6 lg:w-1/4`

export const Vendor = tw(P)`tracking-wide mb-0-4`
export const ProductTitle = tw(H4)`text-lmd font-sans font-light leading-relaxed uppercase mb-0-4`

export const SplitSizing = tw.div`mb-1-6 bg-grey-DEFAULT py-1-8 px-1-6`
export const SplitAttributes = tw.div`flex items-center justify-start -mx-0-2`
export const SplitAttribute = tw.div`px-0-2 max-w-7-5`
export const AttributeLabel = tw(P)`mb-1-2 text-xs`
export const AttributeInput = tw(Input)`w-full text-xs text-center bg-light h-3 text-xs tracking-relaxed`
export const SplitButton = styled(TextButton)`
  ${tw`text-xs px-1-2 tracking-wide`}
  ${props => (props.disabled ? tw`text-grey-darkest pointer-events-none` : ``)}
`

export const Wrapper = styled.div`
  ${props => (props.index ? tw`mb-1-2` : tw`mb-1-6`)}
`

export const QuantityWrapper = styled.div`
  ${props => (props.hidden ? tw`hidden` : ``)}
`

export const ProductPriceWrapper = tw.div`w-full flex justify-between`
export const PaymentLabel = tw(P)`mb-1-2 text-xs text-grey-darker leading-xloosest`
export const PaymentLinkLabel = tw.span`underline cursor-pointer text-dark hover:no-underline`

export const BreadcrumbWrapper = tw.div`mt-1-2 mb-4 md:mt-0`
