import React from "react"
import { Link } from "gatsby"

import { ListStyledItem, ListStyledLink } from "../NavigationMainStyles"

interface Props {
  active: boolean
  items: Array<any>
  link: object
  setActive: any
  title: string
}

export const NavigationMainItem = ({ title, link, items, active, setActive }: Props) => (
  <ListStyledItem>
    {items && items.length ? (
      <ListStyledLink onClick={() => setActive(!active)} inactive={active ? `active` : undefined} title={title}>
        {title}
      </ListStyledLink>
    ) : (
      <ListStyledLink as={Link} inactive={active ? `active` : undefined} onClick={() => setActive(false)} title={title} to={link.url}>
        {title}
      </ListStyledLink>
    )}
  </ListStyledItem>
)
