import gql from "graphql-tag"

import { VARIANT_WITH_PRODUCT_FRAGMENT } from "./variantWithProductFragment"

export const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    name
    orderNumber
    currencyCode
    financialStatus
    fulfillmentStatus
    statusUrl
    customerUrl
    originalTotalPrice {
      amount
      currencyCode
    }
    subtotalPrice {
      amount
      currencyCode
    }
    successfulFulfillments {
      fulfillmentLineItems(first: 50) {
        edges {
          node {
            lineItem {
              title
              quantity
            }
          }
        }
      }
    }
    totalShippingPrice {
      amount
      currencyCode
    }
    totalTax {
      amount
      currencyCode
    }
    totalRefunded {
      amount
      currencyCode
    }
    processedAt
    canceledAt
    shippingAddress {
      address1
      address2
      firstName
      lastName
      city
      province
      zip
      country
    }
    lineItems(first: 100) {
      edges {
        cursor
        node {
          title
          variant {
            ...VariantWithProductFragment
          }
          quantity
          customAttributes {
            key
            value
          }
          discountAllocations {
            allocatedAmount {
              amount
              currencyCode
            }
          }
          currentQuantity
          discountedTotalPrice {
            amount
            currencyCode
          }
          originalTotalPrice {
            amount
            currencyCode
          }
        }
      }
    }
  }
  ${VARIANT_WITH_PRODUCT_FRAGMENT}
`
