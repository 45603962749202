import React, { useEffect } from "react"

import { useCore } from "@hooks/useCore"

export const withBooking = Component => ({ name = `Booking`, active, booking, setActive, prefix }) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const selector = `bookings-container`
  const id = `${prefix ? `${prefix}-` : ""}${selector}`

  useEffect(() => {
    const target = document.querySelector(`#${id}`)

    if (isBrowser && window.Calendly) {
      if (booking) {
        window.Calendly.initInlineWidget({
          url: booking,
          parentElement: target,
          prefill: {},
          utm: {},
        })
      } else {
        setTimeout(() => {
          if (target) {
            target.innerHTML = ""
          }
        }, 300)
      }
    }
  }, [booking])

  Component.displayName = name
  return <Component active={active} id={id} setActive={setActive} />
}
