import styled from "@emotion/styled"
import tw from "twin.macro"

import Icon from "static/icons/theme/close.svg"

export const Outer = styled.div`
  ${tw`fixed inset-0 z-50 flex items-center justify-center transition-all duration-200`}
  ${props => (props.active ? tw`visible` : tw`invisible`)} ${props => (props.active ? tw`duration-500` : ``)}
`
export const Inner = styled.div`
  ${tw`px-1-2 lg:px-0 w-full transform transition-all duration-200 ease-cubic`}
  ${props => (props.active ? tw`duration-500 translate-y-0` : tw`translate-y-full`)}
  ${props => (props.active ? tw`opacity-100` : tw`opacity-0`)}
  ${props => (!props.noOverflow ? tw`max-h-8/10-vh overflow-x-hidden overflow-y-auto` : ``)}
  ${props => (props.width === `lg` ? tw`max-w-110` : tw`max-w-76-6`)}
`
export const Content = styled.div`
  ${tw`flex flex-col items-center justify-center`}
  ${props => (!props.transparent ? tw`bg-light text-dark px-1-6 pt-1-6 pb-3-2` : ``)}
`
export const Bg = styled.div`
  ${tw`fixed bg-dark inset-0 cursor-pointer transition-all duration-200`}
  ${props => (props.active ? tw`opacity-70` : tw`opacity-0`)}
  ${props => (props.active ? tw`duration-500` : ``)}
`
export const StyledIcon = styled(Icon)`
  ${tw`block w-2-4 h-2-4`}
`
export const Close = styled.button`
  ${tw`self-end cursor-pointer hover:opacity-70 focus:outline-none`}
`
