import React, { createContext, useEffect, useState, useMemo } from "react"
import firebase from "firebase/compat/app"
// import "firebase/functions"
import "firebase/compat/auth"
import "firebase/firestore"
import { useCore } from "@hooks/useCore"
import { useGlobal } from "@hooks/useGlobal"

export const FirebaseContext = createContext({ firebase: null, app: null })

export const FirebaseProvider = ({ children, config }) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const { handleGlobalGroupReady } = useGlobal()
  const [app, setFirebase] = useState(null)
  const development = process.env.NODE_ENV === "development"

  useEffect(() => {
    handleGlobalGroupReady("firebase")
  }, [])

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: config.apiKey,
        authDomain: config.authDomain,
        databaseURL: config.databaseURL,
        projectId: config.projectId,
      })
      firebase
        .auth()
        .signInAnonymously()
        .catch(error => console.error("Unable to authenticate", error))
    }

    const firebaseApp = firebase.apps.length
      ? firebase.apps[0]
      : firebase.initializeApp({
          apiKey: config.apiKey,
          authDomain: config.authDomain,
          databaseURL: config.databaseURL,
          projectId: config.projectId,
        })

    // if (process.env.NODE_ENV === "development")
    //   firebaseApp.firestore().settings({
    //     host: "localhost:8082",
    //     ssl: false,
    //   })

    if (!app) setFirebase(firebaseApp)
    return
  }, [config, development])

  const contextValue = useMemo<ContextProps>(
    () => ({
      firebase,
      app,
    }),
    [firebase, app]
  )

  return isBrowser ? <FirebaseContext.Provider value={contextValue}>{children}</FirebaseContext.Provider> : <>{children}</>
}

export const withFirebase = Component => props => (
  <FirebaseContext.Consumer>{({ firebase }) => <Component {...props} firebase={firebase} />}</FirebaseContext.Consumer>
)
