import React, { useCallback } from "react"

import { withNavigationSearch } from "./withNavigationSearch"
import { NavigationSearchForm } from "./Form/NavigationSearchForm"
import { Wrapper, InputWrapper, StyledContainer } from "./NavigationSearchStyles"
import { SearchFormResult } from "./Form/SearchFormResult"

import { ReactifySearchProvider, Sensors } from "@usereactify/search"
import { navigate } from "gatsby"
import { useDefaultConfig } from "@app/hooks/useDefaultConfig"
import { useLocation } from "@app/hooks/useLocation"

// import { NavigationSearchSuggestions } from "./Suggestions/NavigationSearchSuggestions"
// import { SearchComponents } from "@hooks/useSearch"
// import { SearchSensorHidden } from "../../Search/Sensors/SearchSensorHidden"
// import { SearchSensorPublished } from "../../Search/Sensors/SearchSensorPublished"
interface Props {
  activeSearch: boolean
  inverted: boolean
  location: any
  reference?: any
  setActiveSearch: any
}

export const NavigationSearch = withNavigationSearch(({ activeSearch, setActiveSearch, inverted }: Props) => {
  const { shopDomain } = useLocation()
  const handleRedirect = useCallback((type, url) => ["search", "redirect"].includes(type) && navigate(url), [])

  return (
    <ReactifySearchProvider shopifyPermanentDomain={shopDomain ?? ""} mode="instant-search" onRedirect={handleRedirect}>
      <Sensors />
      <Wrapper active={activeSearch} inverted={inverted}>
        <StyledContainer active={activeSearch}>
          <InputWrapper>
            <NavigationSearchForm active={activeSearch} setActive={setActiveSearch} />
          </InputWrapper>
          <SearchFormResult setActiveSearch={setActiveSearch} />
          {/* <SearchSensorHidden /> */}
          {/* <SearchSensorPublished /> */}
          {/* <SearchComponents.ReactiveList
        {...suggestionsConfig}
        renderNoResults={() => null}
        render={({ data }) => value?.length > 2 && data?.length > 1 && <NavigationSearchSuggestions data={data} setActive={setActiveSearch} />}
      /> */}
        </StyledContainer>
      </Wrapper>
    </ReactifySearchProvider>
  )
})
