import React from "react"

import { Variant } from "../../../../types"

import { Attribute, Caps } from "../CartStyles"

interface Props {
  drawer: boolean
  lineItem: any
  variant: Variant
}

export const CartItemOptions = ({ drawer, lineItem, variant }: Props) => (
  <>
    {variant?.selectedOptions
      ?.filter(opt => !opt.value.includes("Default"))
      ?.map(opt => (
        <Attribute drawer={drawer} key={opt.name}>
          <Caps>{opt.name}</Caps>: {opt.value}
        </Attribute>
      ))}

    {lineItem?.customAttributes?.map(
      ({ key, value }) =>
        !key.startsWith("_") && (
          <Attribute drawer={drawer} key={key}>
            <Caps>{key.replace("-", " ")}</Caps>: {value}
          </Attribute>
        )
    )}
  </>
)
