import { useSearch } from "@usereactify/search"

import { useCore } from "@app/hooks/useCore"

import { SearchBar } from "./SearchBar"

const SearchWidget: React.FC = () => {
  const {
    helpers: { isBrowser },
  } = useCore()

  return isBrowser ? <SearchInput /> : <SearchPlaceholder />
}

const SearchInput: React.FC = () => {
  //   const locales = useLocale(LOCALE_KEYS.SEARCH)

  return <SearchBar placeholder={"What are you looking for?"} />
}

const SearchPlaceholder: React.FC = () => {
  //   const locales = useLocale(LOCALE_KEYS.SEARCH)

  return <SearchBar />
}

export { SearchWidget }
