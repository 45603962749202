import React from "react"
import { Link } from "gatsby"

import { withCartItem } from "./withCartItem"
import { Image } from "../../Image/Image"
import { CartItemPrice } from "./CartItemPrice"
import { CartItemAttributes } from "./CartItemAttributes"
import { CartItemOptions } from "./CartItemOptions"
import { CartItemQuantity } from "./CartItemQuantity"
import { P } from "../../Styled/Text"
import {
  Mobile,
  LineItem,
  ImageWrapper,
  RatioWrapper,
  ImageRatio,
  PinnedImage,
  LineItemContent,
  Title,
  Price,
  Pricing,
  Info,
  LineItemTitle,
  Actions,
  Divider,
  LineItemDetails,
  LineItemTextButton,
  IconInactive,
  PaymentOptionLabel,
  PaymentOptionLinkLabel,
} from "../CartStyles"

interface Props {
  customPaymentEnabled: boolean
  drawer: boolean
  grouped: Array<any>
  handleQuantityChange: Function
  handleRemove: Function
  lineItem: any
  locales: any
  paymentTerms: any
  product: any
  productURL: string
  removing: boolean
  setActiveCart: Function
  setShowPopup: Function
  variant: boolean
}

export const CartItem = withCartItem(
  ({
    customPaymentEnabled,
    drawer,
    grouped,
    handleQuantityChange,
    handleRemove,
    lineItem,
    locales,
    paymentTerms,
    product,
    productURL,
    removing,
    setActiveCart,
    setShowPopup,
    variant,
  }: Props) => (
    <LineItem drawer={drawer} removing={removing}>
      <ImageWrapper drawer={drawer}>
        <RatioWrapper as={Link} onClick={setActiveCart ? () => setActiveCart(false) : null} to={productURL}>
          <ImageRatio />
          {variant?.image?.src && (
            <PinnedImage>
              <Image cover src={variant.image.src} />
            </PinnedImage>
          )}
        </RatioWrapper>
      </ImageWrapper>

      <LineItemContent drawer={drawer}>
        {drawer ? (
          <>
            <LineItemDetails drawer={drawer}>
              <Title as={Link} onClick={setActiveCart ? () => setActiveCart(false) : null} to={productURL}>
                {lineItem.title}
              </Title>
              <P>
                <CartItemPrice money={variant?.priceV2} />
              </P>
            </LineItemDetails>

            {customPaymentEnabled && paymentTerms && (
              <div>
                <PaymentOptionLabel>
                  {paymentTerms}
                  <PaymentOptionLinkLabel onClick={() => setShowPopup(true)}>{locales.cartLink1}</PaymentOptionLinkLabel>
                </PaymentOptionLabel>
              </div>
            )}

            <LineItemDetails drawer={drawer}>
              <CartItemOptions drawer={drawer} variant={variant} />

              <P>
                {locales.quantity}: {lineItem.quantity}
              </P>
            </LineItemDetails>

            <CartItemAttributes drawer grouped={grouped} product={product} />

            <LineItemDetails>
              <LineItemTextButton drawer={drawer} onClick={handleRemove}>
                {locales.remove}
              </LineItemTextButton>
            </LineItemDetails>
          </>
        ) : (
          <>
            <Info>
              <LineItemDetails>
                <LineItemTitle>
                  <Title as={Link} to={productURL}>
                    {lineItem.title}
                  </Title>

                  <Mobile>
                    <P>
                      <CartItemPrice money={variant?.priceV2} />
                    </P>
                  </Mobile>

                  <CartItemOptions lineItem={lineItem} variant={variant} />
                </LineItemTitle>

                <Pricing>
                  <CartItemQuantity handleChange={handleQuantityChange} lineItem={lineItem} />
                  <Price>
                    <CartItemPrice money={variant?.priceV2} />
                  </Price>
                </Pricing>
              </LineItemDetails>

              <CartItemAttributes grouped={grouped} product={product} variant={variant} />
            </Info>

            <Actions>
              <LineItemTextButton onClick={() => handleRemove({ redirect: true })}>{locales.edit}</LineItemTextButton>
              <Divider>|</Divider>
              <LineItemTextButton onClick={handleRemove}>{locales.remove}</LineItemTextButton>
              <Divider>|</Divider>
              <LineItemTextButton onClick={() => handleRemove({ wishlist: true })}>
                <IconInactive /> <p>{locales.addButton}</p>
              </LineItemTextButton>
            </Actions>
          </>
        )}
      </LineItemContent>
    </LineItem>
  )
)
