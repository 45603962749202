import React from "react"

import { MegaItem, MegaSubList, MegaStyledLink, MegaStyledSubLink } from "../NavigationMainStyles"

interface Props {
  link: any
  active: boolean
  index: any
  title: string
  setActive: any
  setActiveImage: any
  items: Array<any>
}

export const NavigationMainMega = ({ active, link, title, items, index, setActive, setActiveImage }: Props) => (
  <MegaItem>
    <MegaStyledLink active={active ? `active` : undefined} to={link.url} title={title} index={index}>
      {title}
    </MegaStyledLink>

    {items && items.length ? (
      <MegaSubList>
        {items.map(item => (
          <MegaStyledSubLink
            active={active ? `active` : undefined}
            key={item.title}
            to={item.link.url}
            onMouseEnter={() => setActiveImage(item.key)}
            onMouseLeave={() => setActiveImage(null)}
            onClick={() => setActive(false)}
            title={item.title}
          >
            {item.title}
          </MegaStyledSubLink>
        ))}
      </MegaSubList>
    ) : null}
  </MegaItem>
)
