import React from "react"

import { useCore } from "@hooks/useCore"

export const withNavigationFooterItem = Component => ({ name = `NavigationFooterItem`, ...props }) => {
  const {
    helpers: { isBrowser },
  } = useCore()

  Component.displayName = name
  return <Component {...props} isBrowser={isBrowser} />
}
