import React from "react"
import { Link } from "gatsby"

import { withNavigationFooterPopup } from "./withNavigationFooterPopup"
import { Popup } from "../../../Modals/Popup/Popup"
import { PrimaryButton } from "../../../Styled/Button"
import { Buttons, Button, Content, StyledH5, StyledP } from "../NavigationFooterStyles"

interface Props {
  active: boolean
  linkResolver: any
  locales: any
  products: any
  setActive: any
}

export const NavigationFooterPopup = withNavigationFooterPopup(({ active, linkResolver, locales, products, setActive }: Props) => (
  <Popup active={active} setActive={setActive}>
    <Content>
      <StyledH5>{locales.giftCardTitle}</StyledH5>
      <StyledP>{locales.giftCardContent}</StyledP>

      {products?.giftCards?.option?.values?.length > 0 ? (
        <Buttons>
          {products.giftCards.option.values.map(card => (
            <Button key={card.label}>
              <PrimaryButton as={Link} full={`true`} to={linkResolver({ ...card }, `product`)?.url} onClick={() => setActive(false)}>
                {`${card.label} ${locales.giftCardDescriptor}`}
              </PrimaryButton>
            </Button>
          ))}
        </Buttons>
      ) : null}
    </Content>
  </Popup>
))
