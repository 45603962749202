import styled from "@emotion/styled"
import tw from "twin.macro"

import { Link } from "gatsby"

import Logo from "static/images/kyha.svg"

export const StyledBrandLink = styled(Link)`
  ${tw`block w-1/3 md:w-1/3`}
`
export const StyledLogo = styled(Logo)`
  ${tw`block w-full max-w-11-9 md:mx-auto`}
`
