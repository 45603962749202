import gql from "graphql-tag"

import { CHECKOUT_FRAGMENT } from "../fragments/checkoutFragment"
import { USER_ERROR_FRAGMENT } from "../fragments/userErrorFragment"
import { CHECKOUT_USER_ERROR_FRAGMENT } from "../fragments/checkoutUserErrorFragment"

// Important note: The checkout fragment is used in all of these mutations, so it's important to import it here
// that we have migrated to the Cart API, but to minimise code change, the name of the fragment is still called CheckoutFragment
export const CHECKOUT_CREATE = gql`
  mutation cartCreate($input: CartInput!) {
    cartCreate(input: $input) {
      cart {
        ...CheckoutFragment
      }
      userErrors {
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`

// This seems to be used as the add to cart mutation - weird?  - Blake
// variables have not changed names, but the field that they map to in the query has to reflect Cart API
export const CHECKOUT_LINE_ITEMS_REPLACE = gql`
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(lines: $lines, cartId: $cartId) {
      cart {
        ...CheckoutFragment
      }
      userErrors {
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`
export const CHECKOUT_LINE_ITEMS_ADD = gql`
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(lines: $lines, cartId: $cartId) {
      cart {
        ...CheckoutFragment
      }
      userErrors {
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`

// This isnt used anywhere in the original code which is weird, so ive copied the code to the above to make the code work, needs to be refactoed though as this is not great. - Blake
export const CHECKOUT_LINE_ITEM_ADD = gql`
  mutation checkoutLineItemsAdd($lines: [CartLineInput!]!, $cartId: ID!) {
    cartLinesAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      cart {
        ...CheckoutFragment
      }
      userErrors {
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`

// add a mutate quanity call here. - future blake
export const CHECKOUT_LINE_ITEMS_REMOVE = gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(lineIds: $lineIds, cartId: $cartId) {
      cart {
        ...CheckoutFragment
      }
      userErrors {
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`

export const CHECKOUT_ATTRIBUTES_UPDATE = gql`
  mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${USER_ERROR_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_SHIPPING_ADDRESS_UPDATE = gql`
  mutation checkoutShippingAddressUpdateV2($shippingAddress: MailingAddressInput!, $checkoutId: ID!) {
    checkoutShippingAddressUpdateV2(shippingAddress: $shippingAddress, checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_EMAIL_UPDATE = gql`
  mutation checkoutEmailUpdateV2($buyerIdentity: CartBuyerIdentityInput!, $cartId: ID!) {
    # actually uses cartBuyerIdentityUpdate in storefront api instead of checkout
    cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
      cart {
        ...CheckoutFragment # is actually mapped to cartdata
      }
      userErrors {
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`

export const CHECKOUT_DISCOUNT_APPLY = gql`
  mutation checkoutDiscountCodeApplyV2($discountCode: String!, $checkoutId: ID!) {
    checkoutDiscountCodeApplyV2(discountCode: $discountCode, checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_DISCOUNT_REMOVE = gql`
  mutation checkoutDiscountCodeRemove($checkoutId: ID!) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_GIFTCARDS_APPEND = gql`
  mutation checkoutGiftCardsAppend($giftCardCodes: [String!]!, $checkoutId: ID!) {
    checkoutGiftCardsAppend(giftCardCodes: $giftCardCodes, checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_CUSTOMER_ASSOCIATE = gql`
  mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
    checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_CUSTOMER_DISASSOCIATE = gql`
  mutation checkoutCustomerDisassociateV2($checkoutId: ID!) {
    checkoutCustomerDisassociateV2(checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`
