/* eslint-disable react/prop-types */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"

import { Providers } from "./src/providers/providers"
import { Layout } from "./src/components/Layout/Layout"

import config from "./config.js"

import "./static/styles/index.css"

export const wrapRootElement = ({ element }) => <Providers>{element}</Providers>

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props} settings={config.settings}>
    {element}
  </Layout>
)

export const onRouteUpdate = props => {
  const { location, prevLocation } = props

  if (
    location?.pathname !== prevLocation?.pathname &&
    /collection|search/.test(location?.pathname) &&
    /collection|search/.test(prevLocation?.pathname)
  )
    window.dispatchEvent(new Event("refreshSearch"))
}
