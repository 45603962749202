import React from "react"
import { Helmet } from "react-helmet"

import { withMeta } from "./withMeta"

export const Meta = withMeta(
  ({ languages, metadata, metatags, schemas }): JSX.Element => (
    <Helmet
      htmlAttributes={{ lang: metadata?.lang }}
      title={metadata?.document?.metadata?.overideTitle ? metadata?.document?.metadata?.title : metadata?.title}
    >
      <link href={metadata?.siteURL} rel="home" />
      <link href={metadata?.canonical} rel="canonical" />
      {languages?.map(({ key, href, hrefLang, rel }, index) => (
        <link key={key?.toString() + index} href={href} hrefLang={hrefLang} rel={rel} />
      ))}
      {schemas.map((schema, index) => (
        <script type="application/ld+json" key={index}>
          {JSON.stringify(schema)}
        </script>
      ))}
      {metatags.map(({ name, content }, index) => {
        if (content === "undefined") {
          return null
        }
        return <meta key={name + index} name={name} content={content} />
      })}
      <meta name="facebook-domain-verification" content="im02cm9q38s20v2svb2ytmf2bomik5" />
      <meta name="p:domain_verify" content="983f7f666773e603424c9e3f1e94bd42" />
      <meta name="p:domain_verify" content="9b2df3e6659156d41a757a1945a3edbc" />
      {/** merged from MetaSchema.jsx */}
      {/* todo - update headless-core to support noscript tag. this would be removed once headless-core package support noscript tag  */}
      <noscript>
        {`<img height="1" width="1" style="display:none;" alt="" src="https://ct.pinterest.com/v3/?tid=2613415394336&noscript=1" />`}
      </noscript>
      {/** end */}
    </Helmet>
  )
)
