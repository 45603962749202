import styled from "@emotion/styled"
import tw from "twin.macro"

interface Props {
  width?: string
  as?: string
}

export const Container = styled.div`
  ${tw`w-full mx-auto px-1-2 md:px-1-6`}
  ${({ width }: Props) => (width === `full` ? tw`max-w-full px-0 md:px-0 overflow-hidden` : width === `lg` ? tw`max-w-lg` : tw`max-w-xl`)}
`
