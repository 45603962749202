import React, { useState } from "react"

import { useNavigation } from "@hooks/useNavigation"

export const withNavigationFooter = Component => ({ name = `NavigationFooter` }) => {
  const {
    navigation: { footer },
  } = useNavigation()
  const [activeMenu, setActiveMenu] = useState(null)
  const [activePopup, setActivePopup] = useState(false)
  const [booking, setBooking] = useState(false)

  Component.displayName = name
  return (
    <Component
      activeMenu={activeMenu}
      activePopup={activePopup}
      booking={booking}
      navigation={footer}
      setActiveMenu={setActiveMenu}
      setActivePopup={setActivePopup}
      setBooking={setBooking}
    />
  )
}
