import { SearchFormStyled } from "@app/components/Search/SearchStyles"
import { useSearch } from "@usereactify/search"
import { memo } from "react"

import type { ChangeEvent } from "react"

type SearchBarProps = {
  handleSubmit?: () => void
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  bgColor?: string
  value?: string
  fontSize?: any
  setSearchTerm?: any
}

const SearchBar: React.FC<SearchBarProps> = ({ placeholder }) => {
  const { searchTerm, submitSearchTerm, setSearchTerm } = useSearch()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchTerm(value)
  }

  return (
    <SearchFormStyled
      className={`SearchContainer`}
      onSubmit={event => {
        event?.preventDefault()
        submitSearchTerm(searchTerm)
      }}
    >
      <input name="q" placeholder={placeholder} type="text" aria-label="Open search" onChange={handleInputChange} />
    </SearchFormStyled>
  )
}

const MemoSearchBar = memo(SearchBar)
export { MemoSearchBar as SearchBar }
