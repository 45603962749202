import React from "react"
import { Link } from "gatsby"

import { withNavigationFooterItem } from "./withNavigationFooterItem"
import { Item, SubList, StyledSubLink, StyledButton, StyledButtonText, IconArrowRight } from "../NavigationFooterStyles"

interface Props {
  index: number
  active: boolean
  isBrowser: boolean
  setActiveMenu: any
  setActivePopup: any
  setBooking: any
  title: string
  items: Array<any>
}

export const NavigationFooterItem = withNavigationFooterItem(
  ({ active, items, index, isBrowser, setActivePopup, setActiveMenu, setBooking, title }: Props) => (
    <Item index={index}>
      <StyledButton onClick={() => setActiveMenu(active ? null : title)} title={title}>
        <IconArrowRight active={active ? `active` : undefined} />
        <StyledButtonText>{title}</StyledButtonText>
      </StyledButton>

      {items?.length > 0 ? (
        <SubList active={active ? `active` : undefined}>
          {items.map(item =>
            item?.title?.toLowerCase()?.includes(`gift`) ? (
              <StyledSubLink key={item.title} as={`button`} onClick={() => setActivePopup(true)} title={item.title}>
                {item.title}
              </StyledSubLink>
            ) : item?.link?.url.includes(`calendly`) ? (
              <StyledSubLink key={item.title} as={`button`} onClick={() => setBooking(item.link.url)}>
                {item.title}
              </StyledSubLink>
            ) : item?.link?.url?.includes(`gorgias`) ? (
              <StyledSubLink
                key={item.title}
                as={`button`}
                onClick={isBrowser ? () => (window.GorgiasChat ? window.GorgiasChat.open() : null) : null}
                title={item.title}
              >
                {item.title}
              </StyledSubLink>
            ) : item?.link?.url?.includes("://") || item?.link?.url?.includes("mailto:") || item?.link?.url?.includes("tel:") ? (
              <StyledSubLink
                key={item.title}
                as={`a`}
                href={item.link.url}
                target={item.link.url.includes("://") ? `_blank` : `_self`}
                title={item.title}
              >
                {item.title}
              </StyledSubLink>
            ) : (
              <StyledSubLink key={item.title} as={Link} to={item.link.url} title={item.title}>
                {item.title}
              </StyledSubLink>
            )
          )}
        </SubList>
      ) : null}
    </Item>
  )
)
